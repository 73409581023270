import React, { useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import useStore from "../../../Store";
import MyLoader from "../../MiroComponents/PreLoader";
import { Pagination } from "../../Shared/Paginations";
import { useLocation } from "react-router-dom";
import { FETCHREVIEWSBYSCRAPING } from "../../../Graphql/Mutations";
import { toast } from "react-toastify";
import MsgMiningDialog from "../../MiroComponents/MsgminingDialog";
import { GETALLREVIEWS } from "../../../Graphql/Queries";
import DOMPurify from "dompurify";

function ReviewAnalysis() {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [addDocument] = useMutation(FETCHREVIEWSBYSCRAPING);
  const selectedClient = useStore((state) => state.selectedClient);
  const generateSlug = (name) => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-|-$/g, "");
  };
  const {
    data,
    loading,
    error: reviewsError,
    refetch,
  } = useQuery(GETALLREVIEWS, {
    variables: {
      clientId: selectedClient?.value?._id,
      page: page,
      size: size,
      slug: generateSlug(selectedClient?.label || "task"),
    },
  });

  if (reviewsError) {
    toast.error("Error in fetching reviews");
  }
  const refetchReviews = async (payload) => {
    const response = await addDocument({
      variables: { input: payload },
    });
    if (
      response?.data?.addDocument === "Document added or updated successfully!"
    ) {
      // refetch();
      toast.success(`Reviews Data fetched !`);
    } else {
      toast.error(`Error in fetching reviews`);
    }
  };
  useEffect(() => {
    if (selectedClient?.value?._id) {
      refetch();
    }
  }, [selectedClient, refetch]);

  useEffect(() => {
    if (location?.state?.page) {
      setPage(location?.state?.page);
    }
  }, [location]);

  const TestRowComponent = ({ item }) => {
    return (
      <div
        className="test-details-table-data"
        style={{ justifyContent: "start" }}
      >
        <div
          style={{ maxWidth: "calc(100% / 2)" }}
          className="hypothesis-data"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(item?.content),
          }}
        ></div>
        {item?.url && (
          <div
            className="test-name"
            style={{
              maxWidth: "calc(100% / 2)",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h3>
              <a href={item?.url} target="_blank" rel="noopener noreferrer">
                {item?.url}
              </a>
            </h3>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2>Review Analysis Dashboard</h2>

          <MsgMiningDialog
            client={selectedClient}
            refetchReviews={refetchReviews}
          />
        </div>
      </div>

      {loading ? (
        <MyLoader />
      ) : (
        <div className="table-body">
          <div className="test-details-table-data heading-row">
            <div
              className="hypothesis-data"
              style={{
                maxWidth: "calc(100% / 2)",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span>Review</span>
            </div>
            {data?.getAllReviews?.reviews?.some((item) => item?.url) && (
              <div
                className="test-name"
                style={{
                  maxWidth: "calc(100% / 2)",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h3>Product url</h3>
              </div>
            )}
          </div>
          {data?.getAllReviews?.reviews?.length ? (
            data?.getAllReviews?.reviews?.map((item, i) => (
              <TestRowComponent item={item} key={i} />
            ))
          ) : (
            <div className="table-no-data">No Records</div>
          )}
        </div>
      )}
      <Pagination
        page={page}
        size={size}
        setPage={setPage}
        setSize={setSize}
        totalcount={data?.getAllReviews?.total}
      />
    </div>
  );
}
export default ReviewAnalysis;
