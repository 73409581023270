import { gql } from "@apollo/client";

export const GETCLIENT = gql`
  query Query($id: ID!) {
    client(_id: $id) {
      name
      logo
    }
  }
`;

export const SIGNIFICANCEDATA = gql`
  query GetSignificanceData($options: options) {
    getSignificanceData(options: $options) {
      response
    }
  }
`;
export const GETCLIENTPROPERTIES = gql`
  query ClientProperties($id: ID!) {
    clientProperties(_id: $id)
  }
`;

export const GETCLIENTLEARNINGS = gql`
  query ClientLearnings($client: ID!, $viewId: String!) {
    clientLearnings(client: $client, viewId: $viewId) {
      _id
      name
    }
  }
`;

export const GETCLIENTSCRIPTS = gql`
  query ClientScripts($client: ID!, $viewId: String!) {
    clientScripts(client: $client, viewId: $viewId) {
      _id
      viewId
      clientScriptURL
    }
  }
`;

export const GETCLIENTSCRIPT = gql`
  query ClientScripts($client: ID!, $viewId: String!) {
    clientScripts(client: $client, viewId: $viewId) {
      _id
      clientScriptURL
    }
  }
`;

export const GETCLIENTNEXTSTEPS = gql`
  query ClientNextSteps($client: ID!, $viewId: String!) {
    clientNextSteps(client: $client, viewId: $viewId) {
      _id
      name
    }
  }
`;

export const GETENDEDTESTSWITHREPORTS = gql`
  query EndedTestsWithReports(
    $client: ID!
    $viewId: String!
    $toDate: GraphQLDate!
    $page: Int
    $size: Int
    $fromDate: GraphQLDate!
  ) {
    endedTestsWithReports(
      client: $client
      viewId: $viewId
      toDate: $toDate
      page: $page
      size: $size
      fromDate: $fromDate
    ) {
      total
      page
      size
      tests {
        _id
        name
        enddate
        livedate
        platform
        report
      }
    }
  }
`;

export const GETCLIENTTESTS = gql`
  query GetClientTests(
    $client: ID!
    $viewId: String!
    $status: String
    $page: Int
    $size: Int
    $nameSearch: String
  ) {
    getClientTests(
      client: $client
      viewId: $viewId
      status: $status
      page: $page
      size: $size
      nameSearch: $nameSearch
    ) {
      total
      page
      size
      tests {
        _id
        testid
        name
        testtool
        kameleoontestdetails
        vwotestdetails
        controlimage
        variationimage
        client {
          name
          currency
        }
        trigger {
          name
        }
        tags {
          name
        }
        platform
        status
        livedate
        report
      }
    }
  }
`;

export const GETCLIENTSTRATEGIES = gql`
  query ClientStrategies(
    $client: ID!
    $dates: [GraphQLDate]
    $viewId: String!
  ) {
    clientStrategies(client: $client, dates: $dates, viewId: $viewId) {
      _id
      year
      month
      strategies
    }
  }
`;

export const GETCLIENTSTATS = gql`
  query ClientStats($id: ID!) {
    clientStats(_id: $id) {
      conversionRate
      averageRevenueMonthly
      averageRevenueYearly
      currency
    }
  }
`;

export const TIERS = gql`
  query Tiers {
    tiers {
      _id
      name
      tierName
      colorSwatch
    }
  }
`;
export const GETCLIENTSWITHUSERS = gql`
  query Query(
    $clientFilters: JSON
    $page: Int
    $size: Int
    $testStatus: String
  ) {
    clientsWithUsers(
      clientFilters: $clientFilters
      page: $page
      size: $size
      testStatus: $testStatus
    ) {
      total
      page
      size
      clients {
        _id
        name
        active
        viewId
        logo
        currency
        winningTests
        endedTests
        gaproperties {
          propertyname
          viewId
        }
        users {
          name
          _id
          email
        }
        bqClientId
        defaultDataSet
        winningTests
        endedTests
        onboardDate
        NDAStatus
      }
    }
  }
`;
export const GETCLIENTSWITHTESTS = gql`
  query Query(
    $clientFilters: JSON
    $page: Int
    $size: Int
    $testStatus: String
  ) {
    clientsWithTests(
      clientFilters: $clientFilters
      page: $page
      size: $size
      testStatus: $testStatus
    ) {
      total
      page
      size
      clients {
        _id
        name
        viewId
        logo
        currency
        bqClientId
        defaultDataSet
        gaproperties {
          propertyname
          viewId
        }
        tests {
          name
          _id
          testid
          property
          platform
          testtool
          kameleoontestdetails
          status
          livedate
          recommendedsamplesize

          client {
            _id
          }
        }
      }
    }
  }
`;

export const GETSEGMENTS = gql`
  query Query($searchString: JSON) {
    segments(searchString: $searchString) {
      key
      name
      values
    }
  }
`;

export const GETCLIENTSPORTFOLIO = gql`
  query Query {
    clientPortfolio
  }
`;

export const GETSAVEDPORTFOLIO = gql`
  query Query {
    getPortfolio
  }
`;

export const ADMINDASHBOARDSTATS = gql`
  query Query(
    # $active: Boolean
    # $page: Int
    # $size: Int

    $from: GraphQLDate!
    $to: GraphQLDate!
    $client: String
    $viewId: String
  ) {
    adminDashboardStatistics {
      tests
      clients
      goals
      users
      hypothesis
      typeOfTests
      triggers
    }
    getAdminStats {
      yearlyRevenue
      monthlyRevenue
      winningTests
      endedTests
      activeClients
    }
    revenuePerMonth(_from: $from, _to: $to, _client: $client, viewId: $viewId) {
      month
      revenue
      year
    }
  }
`;

export const REVENUEPERMONTH = gql`
  query RevenuePerMonth(
    $from: GraphQLDate!
    $to: GraphQLDate!
    $client: String
    $viewId: String
  ) {
    revenuePerMonth(_from: $from, _to: $to, _client: $client, viewId: $viewId) {
      month
      revenue
      year
    }
  }
`;

export const SEQUENTIALVALUESFORTEST = gql`
  query GetSequentialValuesForTest($test: ID!) {
    getSequentialValuesForTest(test: $test)
  }
`;

export const GETCLIENTSFORTABLE = gql`
  query Query(
    $clientFilters: JSON
    $page: Int
    $size: Int
    $testStatus: String
  ) {
    clients(
      clientFilters: $clientFilters
      page: $page
      size: $size
      testStatus: $testStatus
    ) {
      total
      page
      size
      clients {
        _id
        name
        active
        viewId
        portfolioClient
        currency
        tier {
          _id
          name
          colorSwatch
        }
        gaproperties {
          propertyname
          viewId
          platform
        }
        tests {
          status
        }
        bqClientId
        defaultDataSet
        retainerValue
        winningTests
        endedTests
        onboardDate
        NDAStatus
        logo
      }
    }
  }
`;

export const GETCLIENTSNAMEANDIDS = gql`
  query Clients($clientFilters: JSON) {
    clients(clientFilters: $clientFilters) {
      clients {
        _id
        name
        currency
        logo
        bqClientId
        defaultDataSet
        gaproperties {
          propertyname
          viewId
          platform
        }
      }
    }
  }
`;

export const GETCLIENTEXPERIMENTS = gql`
  query ClientExperiments(
    $page: Int
    $size: Int
    $client: String
    $viewId: String
  ) {
    clientExperiments(
      page: $page
      size: $size
      client: $client
      viewId: $viewId
    ) {
      total
      page
      size
      experiments {
        _id
        viewId
        createdAt
        name
        experimentUrl
        client {
          name
          viewId
          active
        }
        status
        pageTargeting {
          # experimentId
          condition
          routes
        }
        audienceTargeting {
          # experimentId
          javascript
        }
        variants {
          name
          traffic_allocation
        }
      }
    }
  }
`;

export const GETABEXPERIMENTDETAILS = gql`
  query GetClientExperiment($id: ID) {
    getClientExperiment(_id: $id) {
      _id
      client {
        _id
        name
        logo
      }
      viewId
      status
      createdAt
      audienceTargeting {
        javascript
      }
      experimentUrl
      name
      pageTargeting {
        routes
        condition
      }
      variants {
        name
        slug
        javascript
        css
        traffic_allocation
      }
    }
  }
`;

export const GETADDITIONALREVENUEWITHCLIENTS = gql`
  query ClientsWithAdditionalRevenue($page: Int, $size: Int) {
    clientsWithAdditionalRevenue(page: $page, size: $size) {
      clients {
        _id
        name
        addtionalRevenueDetails
        updatedAt
        testsContributing
        tier {
          _id
          name
          colorSwatch
        }
      }
      page
      size
      total
    }
  }
`;

export const GETALLREPORTS = gql`
  query GetAllReports($page: Int, $size: Int, $filters: JSON) {
    getAllReports(page: $page, size: $size, filters: $filters) {
      size
      total
      page
      reports {
        _id
        name
        learnings
        conclusion
        segmentName
        test {
          _id
          name
        }
        client {
          _id
          name
        }
      }
    }
  }
`;

export const GETREPORTANDTEST = gql`
  query Query($id: String) {
    getReportDetails(_id: $id)
  }
`;
export const GETCLIENTREPORTS = gql`
  query ClientTestReports($client: String!, $conclusion: String) {
    getReports(_client: $client, conclusion: $conclusion) {
      _id
      name
      conclusion
      learnings
      test {
        name
        tags {
          name
        }
        trigger {
          name
        }
      }
    }
  }
`;

export const GETREPORTIDFORTEST = gql`
  query GetReportIdForTest($test: ID!) {
    getReportIdsForTest(test: $test)
  }
`;

export const GETREPORTANDTESTWITHREPORTID = gql`
  query GetReportAndTest($id: ID!) {
    getReportandTest(_id: $id) {
      _id
      name
      client {
        _id
      }
      conclusion
      segmentName
      reportLink
      variant
      test {
        _id
        controlimage
        variationimage
        name
        hypothesis {
          _id
          name
          action
          result
          expectation
        }
        urltargeting
        triggerevents
        platform
      }
      gaTestId
      reportDetails
      learnings
      from
      to
      createdBy {
        _id
      }
      createdAt
    }
  }
`;

export const TWOFACTORQRCODE = gql`
  query Query {
    generateQRCode {
      qrString
      enabled
    }
    myProfile {
      _id
      client {
        logo
        name
      }
    }
  }
`;

export const MYPROFILE = gql`
  query MyProfile {
    myProfile {
      _id
      name
      email
      client {
        _id
        name
        currency
        logo
        bqClientId
        defaultDataSet
        gaproperties {
          viewId
          propertyname
          platform
        }
      }
      role {
        _id
        name
      }
      twoFactor
    }
  }
`;

export const GETPAGEOBJECTS = gql`
  query Query {
    pageelements {
      _id
      name
    }
  }
`;

export const GETTESTTYPES = gql`
  query Query {
    pagetesttypes {
      _id
      name
      details
    }
  }
`;

export const GETGOALS = gql`
  query Query {
    goals {
      _id
      name
      details
    }
  }
`;

export const GETUSERS = gql`
  query Users($page: Int, $size: Int, $roles: JSON, $filters: JSON) {
    users(page: $page, size: $size, roles: $roles, filters: $filters) {
      total
      page
      size
      users {
        _id
        name
        deactivated
        email
        client {
          _id
          name
        }
        role {
          _id
          name
        }
      }
    }
  }
`;

export const ANNOUNCEMENTS = gql`
  query Announcements($page: Int, $size: Int, $announcementFilters: JSON) {
    announcements(
      page: $page
      size: $size
      announcementFilters: $announcementFilters
    ) {
      total
      page
      size
      announcements {
        _id
        active
        title
        description
        forRoles {
          _id
          name
        }
        forUsers {
          _id
          name
        }
        createdAt
      }
    }
  }
`;

export const GETALLROLES = gql`
  query Allroles {
    allroles {
      _id
      name
    }
  }
`;

export const GETHYPOTHESIS = gql`
  query AllHypothesis(
    $page: Int
    $size: Int
    $fullSet: Boolean!
    $pageElementFilter: ID
  ) {
    allHypothesis(
      page: $page
      size: $size
      fullSet: $fullSet
      pageElementFilter: $pageElementFilter
    ) {
      hypotheses {
        _id
        name
        action
        expectation
        result
        tagged
        clients
        tests
        winningClients {
          _id
          client {
            _id
            name
          }
        }
      }
      page
      size
      total
    }
  }
`;

export const SINGLEHYPOTHESIS = gql`
  query SingleHypothesis($id: ID!) {
    singleHypothesis(_id: $id) {
      _id
      hypothesisNumber
      name
      action
      result
      expectation
    }
  }
`;

export const GETTRIGGERS = gql`
  query Query {
    triggers {
      _id
      name
      description
    }
  }
`;

export const GETTAGS = gql`
  query Query {
    tags {
      _id
      name
    }
  }
`;

// FAQS

export const FAQS = gql`
  query Faqs($searchString: String) {
    faqs(searchString: $searchString) {
      _id
      title
      description
      keywords
    }
  }
`;

export const GETSEGMENTCOMBINATIONS = gql`
  query Segmentcombinations($searchString: JSON) {
    segmentcombinations(searchString: $searchString) {
      _id
      name
      gagroup
      combination
      published
      secondaryFilters {
        name
        key
        filters
      }
      segmentType
      client {
        _id
        name
      }
    }
  }
`;

export const FORMPREFILLDATAV2 = gql`
  query Query($filterValues: JSON, $client: ID) {
    formPrefillData(filterValues: $filterValues, client: $client)
  }
`;

export const FORMPREFILLDATA = gql`
  query Query($clientFilters: JSON) {
    clients(clientFilters: $clientFilters) {
      clients {
        _id
        name
        gaproperties {
          propertyname
          viewId
          platform
        }
      }
    }
    pageelements {
      _id
      name
    }
    pagetesttypes {
      _id
      name
    }
    # allHypothesis(fullSet: $fullSet) {
    #   hypotheses {
    #     _id
    #     name
    #     action
    #     result
    #     expectation
    #   }
    # }
    goals {
      _id
      name
    }
    triggers {
      _id
      name
    }
    tags {
      _id
      name
    }
    stageInCustomerJourneys {
      _id
      name
      stages
    }
  }
`;

export const GETGOOGLEANALYTICSDATA = gql`
  query Getdata(
    $viewId: String
    $startDate: String!
    $endDate: String!
    $metrics: [String]
    $filters: String
    $dimensions: [String]
    $group: String
  ) {
    getdata(
      view_id: $viewId
      start_date: $startDate
      end_date: $endDate
      metrics: $metrics
      dimensions: $dimensions
      filters: $filters
      group: $group
    ) {
      response
    }
  }
`;

export const SAVEDSEGMENTSFORTEST = gql`
  query SavedSegmentForTest($id: ID) {
    savedSegmentForTest(_id: $id) {
      _id
      test {
        _id
      }
      segment {
        _id
        name
      }
      segmentFilters
    }
  }
`;

export const SAVEDSEGMENTFILTERS = gql`
  query Query($test: ID!) {
    savedSegmentFilter(test: $test) {
      _id
      segmentFilters
    }
  }
`;

export const GETGOOGLEANALYTICSDATACLIENT = gql`
  query Getdataclient(
    $startDate: String!
    $endDate: String!
    $metrics: [String]
    $filters: String
    $dimensions: [String]
    $group: String
  ) {
    getdataclient(
      start_date: $startDate
      end_date: $endDate
      metrics: $metrics
      dimensions: $dimensions
      filters: $filters
      group: $group
    ) {
      response
    }
  }
`;

export const REVENUEPERUSER = gql`
  query Query($options: rpuoptions) {
    revenuePerUserBeforeTesting(options: $options)
  }
`;

export const CLIENTPEFORMANCEOVERVIEW = gql`
  query Query($client: ID!, $viewId: String!) {
    clientPerformanceOverview(client: $client, viewId: $viewId)
  }
`;

export const ALLABTESTS = gql`
  query AllAbTests($page: Int, $size: Int, $testFilters: JSON) {
    allAbTests(page: $page, size: $size, testFilters: $testFilters) {
      page
      size
      total
      tests {
        _id
        name
        createdAt
        client {
          _id
          name
        }
        variations {
          _id
          name
          csscode
          jscode
          css_code
          baseline
          reset_js
          traffic_allocation
        }
        status
        traffic_allocation
        urltargeting
        audiences {
          _id
          name
          rules_js
        }
        pages {
          _id
          name
        }
        goal {
          _id
          api_name
          name
        }
        environments {
          _id
          name
        }
      }
    }
  }
`;

export const SINGLEABTEST = gql`
  query Singletest($id: ID!) {
    singletest(_id: $id) {
      _id
      name
      status
      createdAt
      urltargeting
      property
      livedate
      client {
        _id
      }
      variations {
        _id
        name
        csscode
        jscode
        css_code
        baseline
        reset_js
        traffic_allocation
      }
      audiences {
        _id
        name
        rules_js
      }
      pages {
        _id
        name
      }
      goal {
        _id
        name
      }
      environments {
        _id
        name
      }
    }
  }
`;

export const ALLTESTS = gql`
  query Alltests(
    $id: String
    $propertyId: String
    $active: Boolean
    $page: Int
    $size: Int
    $testFilters: JSON
  ) {
    alltests(
      _id: $id
      propertyId: $propertyId
      active: $active
      page: $page
      size: $size
      testFilters: $testFilters
    ) {
      total
      page
      size
      tests {
        _id
        name
        client {
          _id
          name
          viewId
          gaproperties {
            propertyname
            viewId
          }
        }
        testtool
        kameleoontestdetails
        vwotestdetails
        goal {
          _id
          name
        }
        hypothesis {
          _id
          name
        }
        testid
        status
        livedate
        report
        tags {
          _id
          name
        }
        pageelement {
          _id
          name
        }
        property
        platform
        controlimage
        variationimage
        pagetesttype {
          _id
          name
          details
        }
        triggerevents
        urltargeting
        selectedevent
        pageaudience
        targetaudience
        page
        pageNotContains
        eventparameter
        htmlcode
        csscode
        jscode
        trigger {
          _id
          name
          createdAt
        }
        revenue {
          type
          value
        }
        significance
        recommendedsamplesize
        stageincustomerjourney {
          _id
          name
        }
        defaultControl
      }
    }
  }
`;

export const ALLNOTIFICATIONS = gql`
  query AllNotifications($page: Int, $size: Int) {
    allNotifications(page: $page, size: $size) {
      total
      page
      size
      notifications {
        _id
        module
        title
        body
        users {
          _id
          client {
            name
          }
        }
        createdBy {
          name
        }
        moduleData
        createdAt
      }
    }
  }
`;

export const NOTIFICATIONSFORUSER = gql`
  query NotificationsForUser($userId: ID!, $page: Int, $size: Int) {
    notificationsForUser(userId: $userId, page: $page, size: $size) {
      total
      page
      size
      notifications {
        _id
        module
        title
        read
        body
        # users {
        #   _id
        #   client {
        #     name
        #   }
        # }
        createdBy {
          name
        }
        moduleData
        createdAt
      }
    }
  }
`;

export const NOTIFICATIONSREADCOUNT = gql`
  query NotificationReadCount($userId: ID!) {
    notificationReadCount(userId: $userId) {
      read
      total
    }
  }
`;

export const MYTESTS = gql`
  query Mytests(
    $testFilters: JSON
    $active: Boolean
    $page: Int
    $size: Int
    $id: String
  ) {
    mytests(
      testFilters: $testFilters
      active: $active
      page: $page
      size: $size
      _id: $id
    ) {
      total
      page
      size
      tests {
        client {
          name
        }
        _id
        name
        testtool
        goal {
          name
        }
        controlimage
        variationimage
        hypothesis {
          name
          action
          result
          expectation
          _id
        }
        property
        testid
        enddate
        status
        livedate
        # tags {
        #   name
        # }
        report
      }
    }
  }
`;

// GET SNIPPETS

export const SNIPPETS = gql`
  query Snippets($page: Int, $size: Int, $filters: JSON) {
    snippets(page: $page, size: $size, filters: $filters) {
      page
      size
      total
      snippets {
        _id
        client {
          _id
          name
        }
        figmaUrl
        csscode
        details
        htmlcode
        jscode
        name
        pageelement {
          _id
          name
        }
        tags {
          _id
          name
        }
        test {
          _id
          name
          controlimage
          variationimage
          hypothesis {
            _id
            name
            action
            expectation
            result
          }
        }
      }
    }
  }
`;

// FEEDBACKS

export const FEEDBACKS = gql`
  query Feedbacks($clientId: ID, $page: Int, $size: Int) {
    feedbacks(clientId: $clientId, page: $page, size: $size) {
      total
      page
      size
      feedbacks {
        title
        client {
          name
        }
        details
      }
    }
  }
`;

// GET RETENTIONS

export const GETCLIENTRETENTIONS = gql`
  query ClientRetentions($client: ID!, $year: Int!) {
    clientRetentions(client: $client, year: $year) {
      _id
      client
      retentions {
        active
        month
        monthName
        retainerValue
        otherPayments
        year
      }
    }
  }
`;

// GET CLIENTS TOTAL INVESTMENT

export const GETCLIENTTOTALINVESTMENT = gql`
  query ClientTotalInvestment($client: ID!, $year: Int, $viewId: String!) {
    clientTotalInvestment(client: $client, year: $year, viewId: $viewId) {
      total
    }
  }
`;

// GET REPORTS HISTORY

export const GETCLIENTSREPORTSHISTORY = gql`
  query Query($client: ID!, $viewId: String!, $test: ID!) {
    getCachedAnalyticsData(client: $client, view_id: $viewId, test: $test)
  }
`;

// GET CLIENT LINKS AND SHEETS

export const GETCLIENTSLINKSANDSHEETS = gql`
  query GetClientLinks(
    $page: Int
    $size: Int
    $client: ID
    $includePrivateDocs: Boolean
  ) {
    getClientLinks(
      page: $page
      size: $size
      client: $client
      includePrivateDocs: $includePrivateDocs
    ) {
      total
      links {
        _id
        createdBy {
          _id
        }
        description
        link
        private
        title
        viewId
        client {
          _id
          name
        }
      }
    }
  }
`;

// GET BIG QUERY DATASETS

export const GETBIGQUERYDATASETS = gql`
  query Query($projectId: String!) {
    getBiQueryDataSets(projectId: $projectId)
  }
`;

// GET CLIENT NOTES

export const GETCLIENTNOTES = gql`
  query Query($client: ID!, $viewId: String!, $page: Int, $size: Int) {
    clientNotes(client: $client, viewId: $viewId, page: $page, size: $size) {
      page
      size
      total
      notes {
        _id
        client {
          _id
        }
        interpretation
        pageType {
          _id
          name
        }
        evidence {
          type
          value
        }
        source
        whatYouSee
      }
    }
  }
`;

//GET NEW IDEAS

export const GETNEWIDEAS = gql`
  query Query($client: ID, $viewId: String, $page: Int, $size: Int) {
    getNewIdeas(client: $client, viewId: $viewId, page: $page, size: $size) {
      page
      size
      total
      notes {
        _id
        client {
          _id
        }
        pageType {
          _id
          name
        }
        ideaName
        priorityDetails
        totalPriority
        hypothesis {
          _id
          name
        }
      }
    }
  }
`;

//GET LANDINGPAGES

export const GETLANDINGPAGES = gql`
  query GetLandingPages($page: Int, $size: Int) {
    getLandingPages(page: $page, size: $size) {
      total
      results {
        _id
        client {
          _id
          name
        }
        dataPoints
        screenshot
        title
      }
    }
  }
`;

//GET APP EVENTS

export const GETAPPEVENTS = gql`
  query Query($page: Int, $size: Int) {
    getAppEvents(page: $page, size: $size) {
      total
      results {
        _id
        clientId {
          _id
          name
        }
        viewId
        eventName
        variant
        browser
        os
      }
    }
  }
`;

// GET CLIENT HYPOTHESIS SHEET

export const GETCLIENTHYPOTHESISSHEET = gql`
  query HypothesisSheetsByClient($client: ID!, $viewId: String!) {
    hypothesisSheetsByClient(client: $client, viewId: $viewId) {
      _id
      hypothesis {
        _id
        name
        action
        hypothesisNumber
        expectation
        result
      }
      tested
      test {
        name
      }
      pageelement {
        _id
        name
      }
      trigger {
        name
      }
    }
  }
`;

// Get CVRReport
export const GETALLCVRREPORT = gql`
  query Query {
    getAllCVRReport {
      _id
      reportLink
      client {
        name
        _id
      }
    }
  }
`;

export const GETCVRREPORT = gql`
  query Query($clientId: ID) {
    getCVRReport(clientId: $clientId) {
      _id
      reportLink
      client {
        _id
        name
      }
    }
  }
`;

// Get Client Survey
export const GETALLCLIENTSURVEY = gql`
  query Query($page: Int, $size: Int) {
    getAllSurvey(page: $page, size: $size) {
      total
      page
      size
      surveys {
        _id
        responses
        user {
          name
          _id
        }
      }
    }
  }
`;

export const GETCLIENTSURVEY = gql`
  query Query($user: ID!) {
    getSurvey(user: $user) {
      _id
      responses
    }
  }
`;

export const CLIENTTARGETINGPAGES = gql`
  query Pages($page: Int, $size: Int, $filters: JSON) {
    pages(page: $page, size: $size, filters: $filters) {
      page
      total
      pageData {
        _id
        name
        rules_js
        urltargetings {
          _id
          type
          url
          url_type
        }
        trigger_js
        deactivation_mode
        api_name
        poll_on_rules
        trigger
        client {
          _id
          name
        }
      }
      size
    }
  }
`;

export const GETAUDIENCES = gql`
  query GetAudiences($page: Int, $size: Int, $filters: JSON) {
    getAudiences(page: $page, size: $size, filters: $filters) {
      total
      size
      page
      audiences {
        _id
        name
        rules_js
        client {
          _id
        }
      }
    }
  }
`;

export const GOALSPAGINATION = gql`
  query GoalsPagination($page: Int, $size: Int, $filters: JSON) {
    goalsPagination(page: $page, size: $size, filters: $filters) {
      total
      size
      page
      goals {
        _id
        name
        api_name
        counting_method
        css_selector
        details
        rules_js
        type
        client {
          _id
          name
        }
      }
    }
  }
`;

export const CLIENTSCRIPT = gql`
  query GetClientScript($client: ID!) {
    getClientScript(client: $client) {
      _id
      activation_mode
      adhere_dnt
      allow_in_iframes
      asset_url
      clientScriptURL
      client {
        _id
        name
      }
      cookie_domain
      csscode
      debug
      is_spa
      jquery_include
      jscode
      max_cookie_lifetime
      restrict_debug
      rules
      rules_tracking
      run_only_on_reinit
      snippet_revision
      scriptUrl
      snippet_version
      storage
      tracker_url
      use_antiflicker
      viewId
    }
  }
`;

export const GETREPORTV2 = gql`
  query Query($getReportV2Id: ID!) {
    getReportV2(id: $getReportV2Id)
  }
`;

export const GETENVIRONMENTS = gql`
  query GetEnvironments($page: Int, $size: Int, $filters: JSON) {
    getEnvironments(page: $page, size: $size, filters: $filters) {
      environments {
        _id
        name
        rules_js
        client {
          _id
        }
      }
      total
      size
      page
    }
  }
`;

export const GETALLREVIEWS = gql`
  query GetAllReviews(
    $page: Int!
    $size: Int!
    $clientId: ID!
    $slug: String!
  ) {
    getAllReviews(page: $page, size: $size, clientId: $clientId, slug: $slug) {
      total
      size
      reviews {
        _id
        url
        content
      }
      page
    }
  }
`;
export const GETTESTPRECALCULATIONPROCESSING = gql`
  query Query(
    $users: Float!
    $transactions: Float!
    $weeks: Float
    $numberofvariants: Float
    $confidencelevel: Float
    $statisticalpower: Float
  ) {
    getTestPreCalculationsAutoDataProcessing(
      users: $users
      transactions: $transactions
      weeks: $weeks
      numberofvariants: $numberofvariants
      confidencelevel: $confidencelevel
      statisticalpower: $statisticalpower
    )
  }
`;
export const DOWNLOADREPORT = gql`
  query DownloadFile($id: ID!) {
    downloadFile(id: $id) {
      mimetype
      filename
      data
    }
  }
`;

export const GETALLREPORTFORCLIENTID = gql`
  query Query($clientId: ID!) {
    getAllFilesByClientID(clientId: $clientId) {
      _id
      clientId
      filename
      content
      timestamp
    }
  }
`;
