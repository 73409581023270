import {
  addDays,
  differenceInCalendarDays,
  endOfDay,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  addMonths,
  isSameDay,
} from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { Calendar } from "./Icons";
import { Button } from "../MiroComponents/Button/Button";

export const DateRangeSelectorComponent = ({
  showCalendar,
  setShowCalendar,
  selectionRange,
  setSelectionRange,
  callBackWhenClose,
  showDays,
  className,
  classNameWrapper,
  color,
}) => {
  const [calendarDirection, setCalendarDirection] = useState("horizontal");
  const [oldSelection, setOldSelection] = useState(null);

  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    //
    last7Days: startOfDay(addDays(new Date(), -7)),
    last28Days: startOfDay(addDays(new Date(), -28)),
    last30Days: startOfDay(addDays(new Date(), -30)),
    last45Days: startOfDay(addDays(new Date(), -45)),
    last60Days: startOfDay(addDays(new Date(), -60)),
    last75Days: startOfDay(addDays(new Date(), -75)),
    last90Days: startOfDay(addDays(new Date(), -90)),
    last12months: startOfDay(addMonths(new Date(), -12)),
    last24months: startOfDay(addMonths(new Date(), -24)),
  };
  function createStaticRanges(ranges) {
    return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
  }
  const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  };

  const defaultStaticRanges = createStaticRanges([
    {
      label: "Today",
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },

    {
      label: `This Week (${format(defineds.last7Days, "eee")} - Today)`,
      range: () => ({
        startDate: defineds.last7Days,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: `Last 28 Days (${format(defineds.last28Days, "eee")} - Today)`,
      range: () => ({
        startDate: defineds.last28Days,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: `Last 30 Days (${format(defineds.last30Days, "eee")} - Today)`,
      range: () => ({
        startDate: defineds.last30Days,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Last 90 Days",
      range: () => ({
        startDate: defineds.last90Days,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Last 12 Months",
      range: () => ({
        startDate: defineds.last12months,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Last 24 Months",
      range: () => ({
        startDate: defineds.last24months,
        endDate: defineds.endOfToday,
      }),
    },
  ]);

  useEffect(() => {
    setOldSelection(selectionRange);
  }, []);

  const manageCalendarDirection = () => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    let direction = "horizontal";
    if (vw <= 767) {
      direction = "vertical";
    } else {
      direction = "horizontal";
    }
    if (calendarDirection !== direction) {
      setCalendarDirection(direction);
    }
  };

  useEffect(() => {
    manageCalendarDirection();
  }, []);

  const days = useMemo(() => {
    let _diff = Number(
      differenceInCalendarDays(
        endOfDay(selectionRange[0]?.endDate),
        startOfDay(selectionRange[0]?.startDate)
      )
    );
    return _diff !== 0 ? _diff : 1;
  }, [selectionRange]);

  return (
    <>
      <span>
        <button
          type="button"
          onClick={(_) => setShowCalendar(!showCalendar)}
          className="btn btn-default d-inline-flex align-items-center"
          style={{
            columnGap: "10px",
          }}
        >
          <Calendar color={color} />
          <span className="range-selected">
            {format(new Date(selectionRange[0]?.startDate), "dd.MMMM.yyyy")} -{" "}
            {format(new Date(selectionRange[0]?.endDate), "dd.MMMM.yyyy")}
          </span>
        </button>
        {showDays && <span className="numdays">Days : {days}</span>}
      </span>

      {showCalendar && (
        <div className={`datepicker-wrapper ${classNameWrapper}`}>
          <DateRangePicker
            showSelectionPreview={true}
            maxDate={new Date()}
            moveRangeOnFirstSelection={true}
            months={2}
            direction={calendarDirection}
            ranges={selectionRange}
            className={className}
            onChange={(item) => {
              setSelectionRange([item.selection]);
            }}
            hasCustomRendering={true}
            staticRanges={defaultStaticRanges}
          />
          <div className="buttons-operations">
            <Button
              className="smart-button small"
              onClick={(_) => {
                setShowCalendar(false);
                setSelectionRange(oldSelection);
              }}
            >
              Cancel
            </Button>
            <Button
              className="smart-button small active"
              onClick={(_) => {
                callBackWhenClose(selectionRange);
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
