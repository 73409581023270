import { differenceInCalendarDays, format } from "date-fns";
import _ from "lodash";
import moment from "moment";
import { VscArrowSwap } from "react-icons/vsc";
import { ConversionIcon } from "../Components/Shared/Icons";
const { jStat } = require("jstat");

export const standardError = (conversionrateusers, users) => {
  const value = Math.sqrt(
    (conversionrateusers * (1 - conversionrateusers)) / users
  );
  return value;
};

export const checkConclusion = (conclusion) => {
  let conclusionClass = "";
  if (conclusion === "winner") {
    conclusionClass = "green";
  }
  if (conclusion === "no uplift") {
    conclusionClass = "red";
  }
  if (conclusion === "neutral") {
    conclusionClass = "yellow";
  }
  return (
    <span
      style={{
        textTransform: "capitalize",
      }}
      className={conclusionClass}
    >
      {conclusion}
    </span>
  );
};

export const checkConversionRate = (number, prefix = "", postfix = "") => {
  number = number.toString();
  number = number.replace(/[$,%]/g, "");
  number = isFinite(number) ? number : 0;
  let numberClass = "";
  if (number > 0) {
    numberClass = "green";
  }
  if (number < 0) {
    numberClass = "red";
  }
  return (
    <span className={numberClass}>
      <ConversionIcon />
      {number}
      {postfix}
    </span>
  );
};

export const checkAdditionalRevenue = (
  number,
  symbol = null,
  prefix = "",
  postfix = "",
  formatColor
) => {
  if (!number) {
    return 0;
  }
  number = number.toString();
  number = number.replace(/[$,%]/g, "");
  number = isFinite(number) ? number : 0;
  let numberPrefix = "";
  let numberClass = "";
  if (number > 0) {
    numberPrefix = "+";
    numberClass = "green";
  }
  if (number < 0) {
    numberClass = "red";
  }
  number = Number(number).toLocaleString("en-US");
  return (
    <span className={formatColor ? numberClass : ``}>
      {prefix}
      {symbol && numberPrefix}
      {number}
      {postfix}
    </span>
  );
};
export const PlatForm = {
  users: "All users",
  desktop: "Desktop",
  mobile: "Mobile",
  "new visitor": "New users",
  "returning visitor": "Returning users",
  "desktop & new visitor": "Desktop & New users",
  "desktop & returning visitor": "Desktop & Returning users",
  "desktop & mobile": "Desktop & Mobile",
  "desktop & users": "Desktop & users",
  "desktop & mobile & new visitor": "Desktop & Mobile & New users",
  "desktop & mobile & returning visitor": "Desktop & Mobile & Returning users",
};
export function formatUserPlatform(input) {
  const mappings = {
    desktop: "Desktop",
    mobile: "Mobile",
    "new visitor": "New users",
    "returning visitor": "Returning users",
  };

  const segments = input.split("&").map((segment) => segment.trim());

  let platforms = [];
  let userTypes = [];

  segments.forEach((segment) => {
    if (mappings[segment]) {
      if (segment === "desktop" || segment === "mobile") {
        platforms.push(mappings[segment]);
      } else {
        userTypes.push(mappings[segment]);
      }
    }
  });

  if (userTypes.length === 0 && platforms.length === 0) {
    return "All users";
  }

  if (userTypes.length === 0) {
    return platforms.join(" & ");
  }

  return [...platforms, ...userTypes].join(" & ");
}
export const CURRENCYSYMBOLSMAP = {
  USD: "$",
  CAD: "CA$",
  EUR: "€",
  AED: "AED",
  AFN: "Af",
  ALL: "ALL",
  AMD: "AMD",
  ARS: "AR$",
  AUD: "AU$",
  AZN: "man.",
  BAM: "KM",
  BDT: "Tk",
  BGN: "BGN",
  BHD: "BD",
  BIF: "FBu",
  BND: "BN$",
  BOB: "Bs",
  BRL: "R$",
  BWP: "BWP",
  BYN: "Br",
  BZD: "BZ$",
  CDF: "CDF",
  CHF: "CHF",
  CLP: "CL$",
  CNY: "CN¥",
  COP: "CO$",
  CRC: "₡",
  CVE: "CV$",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "Dkr",
  DOP: "RD$",
  DZD: "DA",
  EEK: "Ekr",
  EGP: "EGP",
  ERN: "Nfk",
  ETB: "Br",
  GBP: "£",
  GEL: "GEL",
  GHS: "GH₵",
  GNF: "FG",
  GTQ: "GTQ",
  HKD: "HK$",
  HNL: "HNL",
  HRK: "kn",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  INR: "₹",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "Ikr",
  JMD: "J$",
  JOD: "JD",
  JPY: "¥",
  KES: "Ksh",
  KHR: "KHR",
  KMF: "CF",
  KRW: "₩",
  KWD: "KD",
  KZT: "KZT",
  LBP: "L.L.",
  LKR: "SLRs",
  LTL: "Lt",
  LVL: "Ls",
  LYD: "LD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MOP: "MOP$",
  MUR: "MURs",
  MXN: "MX$",
  MYR: "RM",
  MZN: "MTn",
  NAD: "N$",
  NGN: "₦",
  NIO: "C$",
  NOK: "Nkr",
  NPR: "NPRs",
  NZD: "NZ$",
  OMR: "OMR",
  PAB: "B/.",
  PEN: "S/.",
  PHP: "₱",
  PKR: "PKRs",
  PLN: "zł",
  PYG: "₲",
  QAR: "QR",
  RON: "RON",
  RSD: "din.",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SR",
  SDG: "SDG",
  SEK: "Skr",
  SGD: "S$",
  SOS: "Ssh",
  SYP: "SY£",
  THB: "฿",
  TND: "DT",
  TOP: "T$",
  TRY: "TL",
  TTD: "TT$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  UYU: "$U",
  UZS: "UZS",
  VEF: "Bs.F.",
  VND: "₫",
  XAF: "FCFA",
  XOF: "CFA",
  YER: "YR",
  ZAR: "R",
  ZMK: "ZK",
  ZWL: "ZWL$",
};

export const TESTRESULTSFILTERS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Winner",
    value: "winner",
  },
  {
    label: "Neutral",
    value: "neutral",
  },
  {
    label: "No Uplift",
    value: "no uplift",
  },
];

export const REPORTSTYPES = [
  {
    label: "Reports / Ended Tests",
    value: "ended",
  },
  {
    label: "Drafts / Upcoming Tests",
    value: "draft",
  },
  {
    label: "Tests Timeline",
    value: "timeline",
  },
];

export const zScore = (
  controlTransPerSess,
  variantTransPerSess,
  controlStdError,
  variantStdError
) => {
  const value =
    (controlTransPerSess - variantTransPerSess) /
    Math.sqrt(Math.pow(controlStdError, 2) + Math.pow(variantStdError, 2));
  //   console.log(value);
  return value;
};

// create function to calculate number of days between two dates

export const RowHeading = ({ headings, type }) => {
  return (
    <div
      className={`test-details-table-data ${
        type === "heading" ? `heading-row` : ``
      }`}
    >
      {headings.map(({ name, classes }, i) => (
        <div className={classes} key={i}>
          <span>{name}</span>
        </div>
      ))}
    </div>
  );
};

export const targetingTypes = [
  { label: "Page Targeting", value: "pagetargeting" },
  { label: "Audience Targeting", value: "audiencetargeting" },
  // { label: "Event Targeting", value: "eventtargeting" },
];

export const pageTargetingConditions = [
  { label: "Matches", value: "matches" },
  { label: "Not Matches", value: "notMatches" },
  { label: "Simple Match", value: "simpleMatch" },
  { label: "Regex", value: "regex" },
];

export const returnClientOptionsNameId = (clients) => {
  return clients?.map((c) => {
    return {
      label: c?.name,
      value: c?._id,
    };
  });
};

export const experimentModes = [
  "preview",
  "live",
  "draft",
  "paused",
  "discard",
];

export const returnClientOptions = (clients) => {
  return clients?.map((c) => {
    return {
      label: c?.name,
      value: JSON.stringify({
        _id: c?._id,
        gaproperties: c?.gaproperties,
        currency: c?.currency,
        logo: c?.logo,
        bqClientId: c?.bqClientId,
        defaultDataSet: c?.defaultDataSet,
      }),
    };
  });
};

export const logOut = () => {
  localStorage.removeItem("codebase_token");
  localStorage.removeItem("codebasepersiststore");
  window.location.href = "/auth/login";
};

export const testName = (testname) => {
  const splitname = testname.split("_");
  return splitname.indexOf("ACC") > -1
    ? `${splitname[splitname.length - 2] ?? "" + "_" ?? ""}${
        splitname[splitname.length - 1]
      }`
    : splitname[splitname.length - 1];
};

export const platforms = [{ label: "GA4", value: "GA4" }];
export const NormalDist = (z) => {
  if (isNaN(z)) return 0;
  const mean = 0,
    sd = 1;
  return Number(jStat.normal(mean, sd).cdf(z));
};

export const AdditionalTransactionsPerMonth = (
  controlConversionRateUsers,
  variantConversionRateUsers,
  controlUsers,
  variantUsers,
  days
) => {
  const monthlyTraffic = ((+controlUsers + +variantUsers) / days) * 30;
  const value =
    variantConversionRateUsers * monthlyTraffic -
    controlConversionRateUsers * monthlyTraffic;
  return Math.round(value);
};

export const AdditionalRevenuePerMonth = (additTransPerMonth, avgOrderVal) => {
  const value = additTransPerMonth * avgOrderVal;
  return value.toFixed(2);
};

export const AdditionalRevenuePerYear = (additRevPerMonth) => {
  const value = additRevPerMonth * 12;
  return value.toFixed(2);
};

export const convertDate = (dateString) => {
  const year = +dateString.substring(0, 4);
  const month = +dateString.substring(4, 6);
  const day = +dateString.substring(6, 8);

  const datem = new Date(year, month - 1, day);
  return format(datem, "d, MMM");
};

export const ValidateEmail = (mail) => {
  if (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail
    )
  ) {
    return true;
  }
  return false;
};

export const CsvAllowedFields = () => {
  return {
    goals: ["name", "details"],
    page_elements: ["name"],
    psychological_triggers: ["name", "description"],
    stages_in_customer_journey: ["name", "stages"],
    hypothesis: ["name", "action", "expectation", "result"],
  };
};

export const monthlyRevenue = (data) => {
  const _ = data
    ?.filter((win) => win?.conclusion === "winner")
    ?.reduce(
      (acum, item) =>
        item?.reportDetails?.experimentResults.reduce(
          (ineracum, it) => Number(it?.additionalrevenuepermonth) + ineracum,
          0
        ) + acum,
      0
    )
    .toFixed(2);
  return `${Number(_).toLocaleString("en-US")}`;
};

export const yearlyRevenue = (data) => {
  const _ = data
    ?.filter((win) => win?.conclusion === "winner")
    ?.reduce(
      (acum, item) =>
        item?.reportDetails?.experimentResults.reduce(
          (ineracum, it) => Number(it?.additionalrevenueperyear) + ineracum,
          0
        ) + acum,
      0
    )
    .toFixed(2);
  return `${Number(_).toLocaleString("en-US")}`;
};

export const dateRangetoDays = (livedate) => {
  const date = livedate ? new Date(Number(livedate)) : new Date();

  const formattedDate = `${date.getFullYear()}${String(
    date.getMonth() + 1
  ).padStart(2, "0")}${String(date.getDate()).padStart(2, "0")}`;

  const days = differenceInCalendarDays(new Date(), date) + 1;
  return days;
};

export const daysToEvaluateTest = (selectionRange) => {
  const days =
    Number(
      differenceInCalendarDays(
        selectionRange[0]?.endDate,
        selectionRange[0]?.startDate
      )
    ) + 1;
  if (days < 30) {
    return (
      <span>
        - Results can probably evaluated in:
        <strong className="d-inline-flex ms-1 me-1">
          &nbsp;{30 - Number(days)} days
        </strong>
      </span>
    );
  } else {
    return <span className="">- Results can be evaluated now</span>;
  }
};

export const CondifenceOfUpliftOptions = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  scales: {
    y: {
      grid: {
        display: true,
        color: "rgb(255 255 255 / 5%)",
      },
    },
    x: {
      grid: {
        display: true,
        color: "rgb(255 255 255 / 5%)",
      },
      // suggestedMin: 50,
      // suggestedMax: 90,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Probability of being a winner",
    },
    datalabels: {
      color: "#fff",
      font: {
        weight: "400",
        family: "'Neue Haas Grotesk Text Pro', 'sans-serif'",
        size: 12,
        lineHeight: "110%",
      },
      formatter: function (value, context) {
        return value + " % ";
      },
    },
  },
};

export const TimeSeriesOptions = {
  responsive: true,
  scales: {
    x: {
      grid: {
        display: true,
        color: "rgb(255 255 255 / 5%)",
      },
    },
    y: {
      grid: {
        color: "rgb(255 255 255 / 5%)",
        drawTicks: true,
      },
    },
  },
  plugins: {
    legend: {
      position: "top",
      font: {
        size: 14,
      },
    },
    title: {
      display: false,
      text: `Conversions Rate Time Series`,
    },
    datalabels: {
      display: false,
      color: "#fff",
    },
  },
};

export const averageUpliftSuccessfullTests = (reports, reportslength) => {
  const report =
    reports
      .filter((rp) => rp?.conclusion === "winner")
      .reduce(
        (result, rp) =>
          rp?.reportDetails?.experimentResults.reduce(
            (ineracum, it) => Number(it?.uplift) + ineracum,
            0
          ) + result,
        0
      ) / reportslength;
  return !isNaN(report) ? report.toFixed(2) : 0;
};

export const successRate = (reports, endedTest) => {
  const _ = reports?.filter((sr) => sr?.conclusion === "winner")?.length;
  return !isNaN((_ / endedTest) * 100) ? ((_ / endedTest) * 100).toFixed(2) : 0;
};

export const ScrubberHandle = () => {
  return (
    <div className="scrubber-handle">
      <div className="scrubber-handle-inner">
        <button className="border-0 bg-dark rounded-circle">
          <span className="toottip-span text-white m-0">
            <VscArrowSwap />
          </span>
        </button>
      </div>
    </div>
  );
};

export const YearsList = () => {
  var min = new Date().getFullYear() - 3;
  var max = min + 6;
  var years = [];
  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years.reverse();
};

export const MonthsList = () => {
  return Array.from({ length: 12 }, (item, i) => {
    return {
      name: new Date(0, i).toLocaleString("en-US", { month: "long" }),
      month: Number(
        new Date(0, i).toLocaleString("en-US", { month: "2-digit" })
      ),
    };
  });
};

export const ToFixedNumberWithoutRoundoff = (number) => {
  const flooredNumber = Math.floor(number * 100);
  const formattedNumber = flooredNumber / 100;
  return formattedNumber;
};

export const MonthsListShort = () => {
  return Array.from({ length: 12 }, (item, i) => {
    return {
      name: new Date(0, i).toLocaleString("en-US", { month: "short" }),
      month: Number(
        new Date(0, i).toLocaleString("en-US", { month: "2-digit" })
      ),
    };
  });
};

export const experimentEndResults = (
  experimentsData,
  selectionRange,
  currency
) => {
  let dataResponse = [];
  const controldata = experimentsData?.find(
    (edata) =>
      edata?.controlvariant.toLowerCase()?.indexOf("control") > -1 ||
      edata?.controlvariant.toLowerCase()?.indexOf("reference") > -1
  );

  const _revenuecontrol = controldata?.experiments
    ?.reduce((cumm, vexpval) => cumm + Number(vexpval?.transactionRevenue), 0)
    .toFixed(2);

  const controlconversionrateusers =
    controldata?.experiments?.reduce(
      (transtotal, item) => transtotal + Number(item?.transactions),
      0
    ) / controldata?.users;
  const _variantstandarderror = standardError(
    controlconversionrateusers,
    controldata?.users
  );
  // VARIANTS DATA
  const averagerevenueperusercontrol = _revenuecontrol / controldata?.users;
  const averageordervaluecontrol =
    _revenuecontrol /
    controldata?.experiments?.reduce(
      (transtotal, item) => transtotal + Number(item?.transactions),
      0
    );

  const variantsdata = experimentsData?.filter(
    (edata) =>
      edata?.controlvariant?.toLowerCase()?.indexOf("control") === -1 ||
      edata?.controlvariant?.toLowerCase()?.indexOf("reference") === -1
  );

  let days = 0;
  if (selectionRange) {
    days =
      Number(
        differenceInCalendarDays(
          new Date(selectionRange[0].endDate),
          new Date(selectionRange[0].startDate)
        )
      ) + 1;
  }

  Promise.all(
    variantsdata.map((vdata, i) => {
      let dataobj = {};
      const variantconversionrateusers =
        vdata?.experiments?.reduce(
          (transtotal, item) => transtotal + Number(item?.transactions),
          0
        ) / vdata?.users;
      let uplift = (
        (variantconversionrateusers / controlconversionrateusers - 1) *
        100
      ).toFixed(2);
      uplift = !isNaN(uplift) ? uplift : 0;
      const additionaltransactionspermonthvariant =
        AdditionalTransactionsPerMonth(
          controlconversionrateusers,
          variantconversionrateusers,
          controldata?.users,
          vdata?.users,
          days
        );
      const _transactions = vdata?.experiments?.reduce(
        (cumm, vexpval) => cumm + Number(vexpval?.transactions),
        0
      );
      const _revenue = vdata?.experiments
        ?.reduce(
          (cumm, vexpval) => cumm + Number(vexpval?.transactionRevenue),
          0
        )
        .toFixed(2);

      const _averageordervalue = _revenue / _transactions;
      const _averagerevenueperuser = _revenue / vdata?.users;
      // console.log(
      //   _revenue,
      //   _revenuecontrol,
      //   controldata?.users,
      //   vdata?.users,
      //   averagerevenueperusercontrol
      // );
      // const _additionrevenueduringtime = _revenue - _revenuecontrol;
      const _additionrevenueduringtime =
        +_revenue +
        +_revenuecontrol -
        (+controldata?.users + +vdata?.users) * averagerevenueperusercontrol;
      const _additionalrevenuepermonthvariant = AdditionalRevenuePerMonth(
        additionaltransactionspermonthvariant,
        _averageordervalue
      );
      const _additionalrevenueperyearvariant = AdditionalRevenuePerYear(
        _additionalrevenuepermonthvariant
      );
      const _controlstandarderror = standardError(
        variantconversionrateusers,
        vdata?.users
      );

      const _zscorevariant = zScore(
        controlconversionrateusers,
        variantconversionrateusers,
        _controlstandarderror,
        _variantstandarderror
      );
      const _pvaluevariant = NormalDist(_zscorevariant);
      const _confidence = ((1 - _pvaluevariant) * 100).toFixed(2);
      dataobj["samplesize"] = 2540;
      dataobj["currency"] = currency;
      dataobj["controlvariant"] = vdata?.controlvariant;
      dataobj["uplift"] = uplift;
      dataobj["additionaltransactionspermonth"] = !isNaN(
        additionaltransactionspermonthvariant
      )
        ? additionaltransactionspermonthvariant
        : 0;
      dataobj["additionalrevenuepermonth"] = !isNaN(
        _additionalrevenuepermonthvariant
      )
        ? _additionalrevenuepermonthvariant
        : 0;
      dataobj["additionalrevenueduringtime"] = !isNaN(
        _additionrevenueduringtime
      )
        ? _additionrevenueduringtime.toFixed(2)
        : 0;
      dataobj["changeinaov"] = (
        _averageordervalue - averageordervaluecontrol
      ).toFixed(2);
      dataobj["changeinrpu"] = (
        _averagerevenueperuser - averagerevenueperusercontrol
      ).toFixed(2);
      dataobj["additionalrevenueperyear"] = !isNaN(
        _additionalrevenueperyearvariant
      )
        ? _additionalrevenueperyearvariant
        : 0;
      dataobj["confidence"] =
        uplift >= 0
          ? Number(_confidence).toFixed(2)
          : Number(100 - _confidence).toFixed(2);
      dataResponse.push(dataobj);
    })
  );
  return dataResponse.sort((a, b) => a?.uplift - b?.uplift);
};

export const PreDefinedDates = () => {
  const dates = [
    {
      label: "Today",
      value: {
        endDate: moment(new Date()).endOf("day"),
        startDate: moment(new Date()).startOf("day"),
      },
    },
    {
      label: "Yesterday",
      value: {
        endDate: moment(new Date()).endOf("day"),
        startDate: moment(new Date()).subtract(1, "days").startOf("day"),
      },
    },
    {
      label: "This Week",
      value: {
        endDate: moment(new Date()),
        startDate: moment(new Date()).startOf("week"),
      },
    },
    {
      label: "Last Week",
      value: {
        endDate: moment(new Date()).subtract(1, "weeks").endOf("weeks"),
        startDate: moment(new Date()).subtract(1, "weeks").startOf("week"),
      },
    },
    {
      label: "This Month",
      value: {
        endDate: moment(new Date()),
        startDate: moment(new Date()).startOf("month"),
      },
    },
    {
      label: "Last Month",
      value: {
        endDate: moment(new Date()).subtract(1, "months").endOf("month"),
        startDate: moment(new Date()).subtract(1, "months").startOf("month"),
      },
    },
    {
      label: "Last 6 Month",
      value: {
        endDate: moment(new Date()),
        startDate: moment(new Date()).subtract(6, "months").startOf("month"),
      },
    },
  ];
  return dates;
};

export const OperatorValues = ({ key }) => {
  if (key === "purchaseRevenue") {
    return [
      { label: "Equals to", value: "==" },
      { label: "Not Equals to", value: "!=" },
      {
        label: "Less than",
        value: "<",
      },
      {
        label: "Greater than",
        value: ">",
      },
      {
        label: "Less than equals to",
        value: "<=",
      },
      {
        label: "Greater than equals to",
        value: ">=",
      },
    ];
  }
  if (key === "precalculation") {
    return [
      { label: "Contains", value: "@" },
      { label: "Not Contains", value: "!@" },
      { label: "Equals to", value: "==" },
      { label: "Not Equals to", value: "!=" },
    ];
  }
  if (key === "products") {
    return [
      // { label: "Contains", value: "@" },
      { label: "Not Contains", value: "!@" },
    ];
  }
  if (key === "transactionIds") {
    return [
      { label: "Not Contains", value: "!@" },
      // {
      //   label: "Contains",
      //   value: "@",
      // },
    ];
  }
  if (key === "buyersWithProductsNotPriced") {
    return [
      {
        label: "Less than",
        value: "<",
      },
      {
        label: "Greater than",
        value: ">",
      },
    ];
  }
  return [];
};

export const currencylists = [
  {
    countryCode: "AD",
    label: "Andorra",
    value: "EUR",
    population: "84000",
    capital: "Andorra la Vella",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "AE",
    label: "United Arab Emirates",
    value: "AED",
    population: "4975593",
    capital: "Abu Dhabi",
    continentName: "Asia",
    currencySymbol: "د.إ",
  },
  {
    countryCode: "AF",
    label: "Afghanistan",
    value: "AFN",
    population: "29121286",
    capital: "Kabul",
    continentName: "Asia",
    currencySymbol: "؋",
  },
  {
    countryCode: "AG",
    label: "Antigua and Barbuda",
    value: "XCD",
    population: "86754",
    capital: "St. John's",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "AI",
    label: "Anguilla",
    value: "XCD",
    population: "13254",
    capital: "The Valley",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "AL",
    label: "Albania",
    value: "ALL",
    population: "2986952",
    capital: "Tirana",
    continentName: "Europe",
    currencySymbol: "Lek",
  },
  {
    countryCode: "AM",
    label: "Armenia",
    value: "AMD",
    population: "2968000",
    capital: "Yerevan",
    continentName: "Asia",
    currencySymbol: "դր.",
  },
  {
    countryCode: "AO",
    label: "Angola",
    value: "AOA",
    population: "13068161",
    capital: "Luanda",
    continentName: "Africa",
    currencySymbol: "Kz",
  },
  {
    countryCode: "AQ",
    label: "Antarctica",
    value: "",
    population: "0",
    capital: "",
    continentName: "Antarctica",
    currencySymbol: "",
  },
  {
    countryCode: "AR",
    label: "Argentina",
    value: "ARS",
    population: "41343201",
    capital: "Buenos Aires",
    continentName: "South America",
    currencySymbol: "$",
  },
  {
    countryCode: "AS",
    label: "American Samoa",
    value: "USD",
    population: "57881",
    capital: "Pago Pago",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "AT",
    label: "Austria",
    value: "EUR",
    population: "8205000",
    capital: "Vienna",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "AU",
    label: "Australia",
    value: "AUD",
    population: "21515754",
    capital: "Canberra",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "AW",
    label: "Aruba",
    value: "AWG",
    population: "71566",
    capital: "Oranjestad",
    continentName: "North America",
    currencySymbol: "ƒ",
  },
  {
    countryCode: "AX",
    label: "Åland",
    value: "EUR",
    population: "26711",
    capital: "Mariehamn",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "AZ",
    label: "Azerbaijan",
    value: "AZN",
    population: "8303512",
    capital: "Baku",
    continentName: "Asia",
    currencySymbol: "₼",
  },
  {
    countryCode: "BA",
    label: "Bosnia and Herzegovina",
    value: "BAM",
    population: "4590000",
    capital: "Sarajevo",
    continentName: "Europe",
    currencySymbol: "KM",
  },
  {
    countryCode: "BB",
    label: "Barbados",
    value: "BBD",
    population: "285653",
    capital: "Bridgetown",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "BD",
    label: "Bangladesh",
    value: "BDT",
    population: "156118464",
    capital: "Dhaka",
    continentName: "Asia",
    currencySymbol: "৳",
  },
  {
    countryCode: "BE",
    label: "Belgium",
    value: "EUR",
    population: "10403000",
    capital: "Brussels",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "BF",
    label: "Burkina Faso",
    value: "XOF",
    population: "16241811",
    capital: "Ouagadougou",
    continentName: "Africa",
    currencySymbol: "CFA",
  },
  {
    countryCode: "BG",
    label: "Bulgaria",
    value: "BGN",
    population: "7148785",
    capital: "Sofia",
    continentName: "Europe",
    currencySymbol: "лв",
  },
  {
    countryCode: "BH",
    label: "Bahrain",
    value: "BHD",
    population: "738004",
    capital: "Manama",
    continentName: "Asia",
    currencySymbol: ".د.ب",
  },
  {
    countryCode: "BI",
    label: "Burundi",
    value: "BIF",
    population: "9863117",
    capital: "Bujumbura",
    continentName: "Africa",
    currencySymbol: "FBu",
  },
  {
    countryCode: "BJ",
    label: "Benin",
    value: "XOF",
    population: "9056010",
    capital: "Porto-Novo",
    continentName: "Africa",
    currencySymbol: "CFA",
  },
  {
    countryCode: "BL",
    label: "Saint Barthélemy",
    value: "EUR",
    population: "8450",
    capital: "Gustavia",
    continentName: "North America",
    currencySymbol: "€",
  },
  {
    countryCode: "BM",
    label: "Bermuda",
    value: "BMD",
    population: "65365",
    capital: "Hamilton",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "BN",
    label: "Brunei",
    value: "BND",
    population: "395027",
    capital: "Bandar Seri Begawan",
    continentName: "Asia",
    currencySymbol: "$",
  },
  {
    countryCode: "BO",
    label: "Bolivia",
    value: "BOB",
    population: "9947418",
    capital: "Sucre",
    continentName: "South America",
    currencySymbol: "$b",
  },
  {
    countryCode: "BQ",
    label: "Bonaire, Sint Eustatius, and Saba",
    value: "USD",
    population: "18012",
    capital: "Kralendijk",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "BR",
    label: "Brazil",
    value: "BRL",
    population: "201103330",
    capital: "Brasília",
    continentName: "South America",
    currencySymbol: "R$",
  },
  {
    countryCode: "BS",
    label: "Bahamas",
    value: "BSD",
    population: "301790",
    capital: "Nassau",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "BT",
    label: "Bhutan",
    value: "BTN",
    population: "699847",
    capital: "Thimphu",
    continentName: "Asia",
    currencySymbol: "Nu.",
  },
  {
    countryCode: "BV",
    label: "Bouvet Island",
    value: "NOK",
    population: "0",
    capital: "",
    continentName: "Antarctica",
    currencySymbol: "kr",
  },
  {
    countryCode: "BW",
    label: "Botswana",
    value: "BWP",
    population: "2029307",
    capital: "Gaborone",
    continentName: "Africa",
    currencySymbol: "P",
  },
  {
    countryCode: "BY",
    label: "Belarus",
    value: "BYR",
    population: "9685000",
    capital: "Minsk",
    continentName: "Europe",
    currencySymbol: "p.",
  },
  {
    countryCode: "BZ",
    label: "Belize",
    value: "BZD",
    population: "314522",
    capital: "Belmopan",
    continentName: "North America",
    currencySymbol: "BZ$",
  },
  {
    countryCode: "CA",
    label: "Canada",
    value: "CAD",
    population: "33679000",
    capital: "Ottawa",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "CC",
    label: "Cocos [Keeling] Islands",
    value: "AUD",
    population: "628",
    capital: "West Island",
    continentName: "Asia",
    currencySymbol: "$",
  },
  {
    countryCode: "CD",
    label: "Democratic Republic of the Congo",
    value: "CDF",
    population: "70916439",
    capital: "Kinshasa",
    continentName: "Africa",
    currencySymbol: "FC",
  },
  {
    countryCode: "CF",
    label: "Central African Republic",
    value: "XAF",
    population: "4844927",
    capital: "Bangui",
    continentName: "Africa",
    currencySymbol: "FCF",
  },
  {
    countryCode: "CG",
    label: "Republic of the Congo",
    value: "XAF",
    population: "3039126",
    capital: "Brazzaville",
    continentName: "Africa",
    currencySymbol: "FC",
  },
  {
    countryCode: "CH",
    label: "Switzerland",
    value: "CHF",
    population: "7581000",
    capital: "Bern",
    continentName: "Europe",
    currencySymbol: "CHF",
  },
  {
    countryCode: "CI",
    label: "Ivory Coast",
    value: "XOF",
    population: "21058798",
    capital: "Yamoussoukro",
    continentName: "Africa",
    currencySymbol: "CFA",
  },
  {
    countryCode: "CK",
    label: "Cook Islands",
    value: "NZD",
    population: "21388",
    capital: "Avarua",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "CL",
    label: "Chile",
    value: "CLP",
    population: "16746491",
    capital: "Santiago",
    continentName: "South America",
    currencySymbol: "$",
  },
  {
    countryCode: "CM",
    label: "Cameroon",
    value: "XAF",
    population: "19294149",
    capital: "Yaoundé",
    continentName: "Africa",
    currencySymbol: "FCFA",
  },
  {
    countryCode: "CN",
    label: "China",
    value: "CNY",
    population: "1330044000",
    capital: "Beijing",
    continentName: "Asia",
    currencySymbol: "¥",
  },
  {
    countryCode: "CO",
    label: "Colombia",
    value: "COP",
    population: "47790000",
    capital: "Bogotá",
    continentName: "South America",
    currencySymbol: "$",
  },
  {
    countryCode: "CR",
    label: "Costa Rica",
    value: "CRC",
    population: "4516220",
    capital: "San José",
    continentName: "North America",
    currencySymbol: "₡",
  },
  {
    countryCode: "CU",
    label: "Cuba",
    value: "CUP",
    population: "11423000",
    capital: "Havana",
    continentName: "North America",
    currencySymbol: "₱",
  },
  {
    countryCode: "CV",
    label: "Cape Verde",
    value: "CVE",
    population: "508659",
    capital: "Praia",
    continentName: "Africa",
    currencySymbol: "Esc",
  },
  {
    countryCode: "CW",
    label: "Curacao",
    value: "ANG",
    population: "141766",
    capital: "Willemstad",
    continentName: "North America",
    currencySymbol: "ƒ",
  },
  {
    countryCode: "CX",
    label: "Christmas Island",
    value: "AUD",
    population: "1500",
    capital: "Flying Fish Cove",
    continentName: "Asia",
    currencySymbol: "$",
  },
  {
    countryCode: "CY",
    label: "Cyprus",
    value: "EUR",
    population: "1102677",
    capital: "Nicosia",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "CZ",
    label: "Czech Republic",
    value: "CZK",
    population: "10476000",
    capital: "Prague",
    continentName: "Europe",
    currencySymbol: "Kč",
  },
  {
    countryCode: "DE",
    label: "Germany",
    value: "EUR",
    population: "81802257",
    capital: "Berlin",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "DJ",
    label: "Djibouti",
    value: "DJF",
    population: "740528",
    capital: "Djibouti",
    continentName: "Africa",
    currencySymbol: "Fdj",
  },
  {
    countryCode: "DK",
    label: "Denmark",
    value: "DKK",
    population: "5484000",
    capital: "Copenhagen",
    continentName: "Europe",
    currencySymbol: "kr",
  },
  {
    countryCode: "DM",
    label: "Dominica",
    value: "XCD",
    population: "72813",
    capital: "Roseau",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "DO",
    label: "Dominican Republic",
    value: "DOP",
    population: "9823821",
    capital: "Santo Domingo",
    continentName: "North America",
    currencySymbol: "RD$",
  },
  {
    countryCode: "DZ",
    label: "Algeria",
    value: "DZD",
    population: "34586184",
    capital: "Algiers",
    continentName: "Africa",
    currencySymbol: "د.ج",
  },
  {
    countryCode: "EC",
    label: "Ecuador",
    value: "USD",
    population: "14790608",
    capital: "Quito",
    continentName: "South America",
    currencySymbol: "$",
  },
  {
    countryCode: "EE",
    label: "Estonia",
    value: "EUR",
    population: "1291170",
    capital: "Tallinn",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "EG",
    label: "Egypt",
    value: "EGP",
    population: "80471869",
    capital: "Cairo",
    continentName: "Africa",
    currencySymbol: "£",
  },
  {
    countryCode: "EH",
    label: "Western Sahara",
    value: "MAD",
    population: "273008",
    capital: "Laâyoune",
    continentName: "Africa",
    currencySymbol: "د.م.",
  },
  {
    countryCode: "ER",
    label: "Eritrea",
    value: "ERN",
    population: "5792984",
    capital: "Asmara",
    continentName: "Africa",
    currencySymbol: "Nfk",
  },
  {
    countryCode: "ES",
    label: "Spain",
    value: "EUR",
    population: "46505963",
    capital: "Madrid",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "ET",
    label: "Ethiopia",
    value: "ETB",
    population: "88013491",
    capital: "Addis Ababa",
    continentName: "Africa",
    currencySymbol: "Br",
  },
  {
    countryCode: "FI",
    label: "Finland",
    value: "EUR",
    population: "5244000",
    capital: "Helsinki",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "FJ",
    label: "Fiji",
    value: "FJD",
    population: "875983",
    capital: "Suva",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "FK",
    label: "Falkland Islands",
    value: "FKP",
    population: "2638",
    capital: "Stanley",
    continentName: "South America",
    currencySymbol: "£",
  },
  {
    countryCode: "FM",
    label: "Micronesia",
    value: "USD",
    population: "107708",
    capital: "Palikir",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "FO",
    label: "Faroe Islands",
    value: "DKK",
    population: "48228",
    capital: "Tórshavn",
    continentName: "Europe",
    currencySymbol: "kr",
  },
  {
    countryCode: "FR",
    label: "France",
    value: "EUR",
    population: "64768389",
    capital: "Paris",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "GA",
    label: "Gabon",
    value: "XAF",
    population: "1545255",
    capital: "Libreville",
    continentName: "Africa",
    currencySymbol: "FCFA",
  },
  {
    countryCode: "GB",
    label: "United Kingdom",
    value: "GBP",
    population: "62348447",
    capital: "London",
    continentName: "Europe",
    currencySymbol: "£",
  },
  {
    countryCode: "GD",
    label: "Grenada",
    value: "XCD",
    population: "107818",
    capital: "St. George's",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "GE",
    label: "Georgia",
    value: "GEL",
    population: "4630000",
    capital: "Tbilisi",
    continentName: "Asia",
    currencySymbol: "ლ",
  },
  {
    countryCode: "GF",
    label: "French Guiana",
    value: "EUR",
    population: "195506",
    capital: "Cayenne",
    continentName: "South America",
    currencySymbol: "€",
  },
  {
    countryCode: "GG",
    label: "Guernsey",
    value: "GBP",
    population: "65228",
    capital: "St Peter Port",
    continentName: "Europe",
    currencySymbol: "£",
  },
  {
    countryCode: "GH",
    label: "Ghana",
    value: "GHS",
    population: "24339838",
    capital: "Accra",
    continentName: "Africa",
    currencySymbol: "GH₵",
  },
  {
    countryCode: "GI",
    label: "Gibraltar",
    value: "GIP",
    population: "27884",
    capital: "Gibraltar",
    continentName: "Europe",
    currencySymbol: "£",
  },
  {
    countryCode: "GL",
    label: "Greenland",
    value: "DKK",
    population: "56375",
    capital: "Nuuk",
    continentName: "North America",
    currencySymbol: "kr",
  },
  {
    countryCode: "GM",
    label: "Gambia",
    value: "GMD",
    population: "1593256",
    capital: "Banjul",
    continentName: "Africa",
    currencySymbol: "D",
  },
  {
    countryCode: "GN",
    label: "Guinea",
    value: "GNF",
    population: "10324025",
    capital: "Conakry",
    continentName: "Africa",
    currencySymbol: "FG",
  },
  {
    countryCode: "GP",
    label: "Guadeloupe",
    value: "EUR",
    population: "443000",
    capital: "Basse-Terre",
    continentName: "North America",
    currencySymbol: "€",
  },
  {
    countryCode: "GQ",
    label: "Equatorial Guinea",
    value: "XAF",
    population: "1014999",
    capital: "Malabo",
    continentName: "Africa",
    currencySymbol: "FCFA",
  },
  {
    countryCode: "GR",
    label: "Greece",
    value: "EUR",
    population: "11000000",
    capital: "Athens",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "GS",
    label: "South Georgia and the South Sandwich Islands",
    value: "GBP",
    population: "30",
    capital: "Grytviken",
    continentName: "Antarctica",
    currencySymbol: "£",
  },
  {
    countryCode: "GT",
    label: "Guatemala",
    value: "GTQ",
    population: "13550440",
    capital: "Guatemala City",
    continentName: "North America",
    currencySymbol: "Q",
  },
  {
    countryCode: "GU",
    label: "Guam",
    value: "USD",
    population: "159358",
    capital: "Hagåtña",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "GW",
    label: "Guinea-Bissau",
    value: "XOF",
    population: "1565126",
    capital: "Bissau",
    continentName: "Africa",
    currencySymbol: "CFA",
  },
  {
    countryCode: "GY",
    label: "Guyana",
    value: "GYD",
    population: "748486",
    capital: "Georgetown",
    continentName: "South America",
    currencySymbol: "$",
  },
  {
    countryCode: "HK",
    label: "Hong Kong",
    value: "HKD",
    population: "6898686",
    capital: "Hong Kong",
    continentName: "Asia",
    currencySymbol: "$",
  },
  {
    countryCode: "HM",
    label: "Heard Island and McDonald Islands",
    value: "AUD",
    population: "0",
    capital: "",
    continentName: "Antarctica",
    currencySymbol: "$",
  },
  {
    countryCode: "HN",
    label: "Honduras",
    value: "HNL",
    population: "7989415",
    capital: "Tegucigalpa",
    continentName: "North America",
    currencySymbol: "L",
  },
  {
    countryCode: "HR",
    label: "Croatia",
    value: "HRK",
    population: "4284889",
    capital: "Zagreb",
    continentName: "Europe",
    currencySymbol: "kn",
  },
  {
    countryCode: "HT",
    label: "Haiti",
    value: "HTG",
    population: "9648924",
    capital: "Port-au-Prince",
    continentName: "North America",
    currencySymbol: "G",
  },
  {
    countryCode: "HU",
    label: "Hungary",
    value: "HUF",
    population: "9982000",
    capital: "Budapest",
    continentName: "Europe",
    currencySymbol: "Ft",
  },
  {
    countryCode: "ID",
    label: "Indonesia",
    value: "IDR",
    population: "242968342",
    capital: "Jakarta",
    continentName: "Asia",
    currencySymbol: "Rp",
  },
  {
    countryCode: "IE",
    label: "Ireland",
    value: "EUR",
    population: "4622917",
    capital: "Dublin",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "IL",
    label: "Israel",
    value: "ILS",
    population: "7353985",
    capital: "Jerusalem",
    continentName: "Asia",
    currencySymbol: "₪",
  },
  {
    countryCode: "IM",
    label: "Isle of Man",
    value: "GBP",
    population: "75049",
    capital: "Douglas",
    continentName: "Europe",
    currencySymbol: "£",
  },
  {
    countryCode: "IN",
    label: "India",
    value: "INR",
    population: "1173108018",
    capital: "New Delhi",
    continentName: "Asia",
    currencySymbol: "₹",
  },
  {
    countryCode: "IO",
    label: "British Indian Ocean Territory",
    value: "USD",
    population: "4000",
    capital: "Diego Garcia",
    continentName: "Asia",
    currencySymbol: "$",
  },
  {
    countryCode: "IQ",
    label: "Iraq",
    value: "IQD",
    population: "29671605",
    capital: "Baghdad",
    continentName: "Asia",
    currencySymbol: "ع.د",
  },
  {
    countryCode: "IR",
    label: "Iran",
    value: "IRR",
    population: "76923300",
    capital: "Tehran",
    continentName: "Asia",
    currencySymbol: "﷼",
  },
  {
    countryCode: "IS",
    label: "Iceland",
    value: "ISK",
    population: "308910",
    capital: "Reykjavik",
    continentName: "Europe",
    currencySymbol: "kr",
  },
  {
    countryCode: "IT",
    label: "Italy",
    value: "EUR",
    population: "60340328",
    capital: "Rome",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "JE",
    label: "Jersey",
    value: "GBP",
    population: "90812",
    capital: "Saint Helier",
    continentName: "Europe",
    currencySymbol: "£",
  },
  {
    countryCode: "JM",
    label: "Jamaica",
    value: "JMD",
    population: "2847232",
    capital: "Kingston",
    continentName: "North America",
    currencySymbol: "J$",
  },
  {
    countryCode: "JO",
    label: "Jordan",
    value: "JOD",
    population: "6407085",
    capital: "Amman",
    continentName: "Asia",
    currencySymbol: "د.ا",
  },
  {
    countryCode: "JP",
    label: "Japan",
    value: "JPY",
    population: "127288000",
    capital: "Tokyo",
    continentName: "Asia",
    currencySymbol: "¥",
  },
  {
    countryCode: "KE",
    label: "Kenya",
    value: "KES",
    population: "40046566",
    capital: "Nairobi",
    continentName: "Africa",
    currencySymbol: "KSh",
  },
  {
    countryCode: "KG",
    label: "Kyrgyzstan",
    value: "KGS",
    population: "5776500",
    capital: "Bishkek",
    continentName: "Asia",
    currencySymbol: "лв",
  },
  {
    countryCode: "KH",
    label: "Cambodia",
    value: "KHR",
    population: "14453680",
    capital: "Phnom Penh",
    continentName: "Asia",
    currencySymbol: "៛",
  },
  {
    countryCode: "KI",
    label: "Kiribati",
    value: "AUD",
    population: "92533",
    capital: "Tarawa",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "KM",
    label: "Comoros",
    value: "KMF",
    population: "773407",
    capital: "Moroni",
    continentName: "Africa",
    currencySymbol: "CF",
  },
  {
    countryCode: "KN",
    label: "Saint Kitts and Nevis",
    value: "XCD",
    population: "51134",
    capital: "Basseterre",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "KP",
    label: "North Korea",
    value: "KPW",
    population: "22912177",
    capital: "Pyongyang",
    continentName: "Asia",
    currencySymbol: "₩",
  },
  {
    countryCode: "KR",
    label: "South Korea",
    value: "KRW",
    population: "48422644",
    capital: "Seoul",
    continentName: "Asia",
    currencySymbol: "₩",
  },
  {
    countryCode: "KW",
    label: "Kuwait",
    value: "KWD",
    population: "2789132",
    capital: "Kuwait City",
    continentName: "Asia",
    currencySymbol: "د.ك",
  },
  {
    countryCode: "KY",
    label: "Cayman Islands",
    value: "KYD",
    population: "44270",
    capital: "George Town",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "KZ",
    label: "Kazakhstan",
    value: "KZT",
    population: "15340000",
    capital: "Astana",
    continentName: "Asia",
    currencySymbol: "лв",
  },
  {
    countryCode: "LA",
    label: "Laos",
    value: "LAK",
    population: "6368162",
    capital: "Vientiane",
    continentName: "Asia",
    currencySymbol: "₭",
  },
  {
    countryCode: "LB",
    label: "Lebanon",
    value: "LBP",
    population: "4125247",
    capital: "Beirut",
    continentName: "Asia",
    currencySymbol: "ل.ل",
  },
  {
    countryCode: "LC",
    label: "Saint Lucia",
    value: "XCD",
    population: "160922",
    capital: "Castries",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "LI",
    label: "Liechtenstein",
    value: "CHF",
    population: "35000",
    capital: "Vaduz",
    continentName: "Europe",
    currencySymbol: "CHF",
  },
  {
    countryCode: "LK",
    label: "Sri Lanka",
    value: "LKR",
    population: "21513990",
    capital: "Colombo",
    continentName: "Asia",
    currencySymbol: "Rs",
  },
  {
    countryCode: "LR",
    label: "Liberia",
    value: "LRD",
    population: "3685076",
    capital: "Monrovia",
    continentName: "Africa",
    currencySymbol: "L",
  },
  {
    countryCode: "LS",
    label: "Lesotho",
    value: "LSL",
    population: "1919552",
    capital: "Maseru",
    continentName: "Africa",
    currencySymbol: "L",
  },
  {
    countryCode: "LT",
    label: "Lithuania",
    value: "LTL",
    population: "2944459",
    capital: "Vilnius",
    continentName: "Europe",
    currencySymbol: "Lt",
  },
  {
    countryCode: "LU",
    label: "Luxembourg",
    value: "EUR",
    population: "497538",
    capital: "Luxembourg",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "LV",
    label: "Latvia",
    value: "LVL",
    population: "2217969",
    capital: "Riga",
    continentName: "Europe",
    currencySymbol: "Ls",
  },
  {
    countryCode: "LY",
    label: "Libya",
    value: "LYD",
    population: "6461454",
    capital: "Tripoli",
    continentName: "Africa",
    currencySymbol: "ل.د",
  },
  {
    countryCode: "MA",
    label: "Morocco",
    value: "MAD",
    population: "33848242",
    capital: "Rabat",
    continentName: "Africa",
    currencySymbol: "د.م.",
  },
  {
    countryCode: "MC",
    label: "Monaco",
    value: "EUR",
    population: "32965",
    capital: "Monaco",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "MD",
    label: "Moldova",
    value: "MDL",
    population: "4324000",
    capital: "Chișinău",
    continentName: "Europe",
    currencySymbol: "L",
  },
  {
    countryCode: "ME",
    label: "Montenegro",
    value: "EUR",
    population: "666730",
    capital: "Podgorica",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "MF",
    label: "Saint Martin",
    value: "EUR",
    population: "35925",
    capital: "Marigot",
    continentName: "North America",
    currencySymbol: "€",
  },
  {
    countryCode: "MG",
    label: "Madagascar",
    value: "MGA",
    population: "21281844",
    capital: "Antananarivo",
    continentName: "Africa",
    currencySymbol: "Ar",
  },
  {
    countryCode: "MH",
    label: "Marshall Islands",
    value: "USD",
    population: "65859",
    capital: "Majuro",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "MK",
    label: "North Macedonia",
    value: "MKD",
    population: "2062294",
    capital: "Skopje",
    continentName: "Europe",
    currencySymbol: "ден",
  },
  {
    countryCode: "ML",
    label: "Mali",
    value: "XOF",
    population: "13796354",
    capital: "Bamako",
    continentName: "Africa",
    currencySymbol: "CFA",
  },
  {
    countryCode: "MM",
    label: "Myanmar",
    value: "MMK",
    population: "53414374",
    capital: "Nay Pyi Taw",
    continentName: "Asia",
    currencySymbol: "K",
  },
  {
    countryCode: "MN",
    label: "Mongolia",
    value: "MNT",
    population: "3086918",
    capital: "Ulaanbaatar",
    continentName: "Asia",
    currencySymbol: "₮",
  },
  {
    countryCode: "MO",
    label: "Macao",
    value: "MOP",
    population: "449198",
    capital: "Macao",
    continentName: "Asia",
    currencySymbol: "MOP$",
  },
  {
    countryCode: "MP",
    label: "Northern Mariana Islands",
    value: "USD",
    population: "53883",
    capital: "Saipan",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "MQ",
    label: "Martinique",
    value: "EUR",
    population: "432900",
    capital: "Fort-de-France",
    continentName: "North America",
    currencySymbol: "€",
  },
  {
    countryCode: "MR",
    label: "Mauritania",
    value: "MRO",
    population: "3205060",
    capital: "Nouakchott",
    continentName: "Africa",
    currencySymbol: "UM",
  },
  {
    countryCode: "MS",
    label: "Montserrat",
    value: "XCD",
    population: "9341",
    capital: "Plymouth",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "MT",
    label: "Malta",
    value: "EUR",
    population: "403000",
    capital: "Valletta",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "MU",
    label: "Mauritius",
    value: "MUR",
    population: "1294104",
    capital: "Port Louis",
    continentName: "Africa",
    currencySymbol: "₨",
  },
  {
    countryCode: "MV",
    label: "Maldives",
    value: "MVR",
    population: "395650",
    capital: "Malé",
    continentName: "Asia",
    currencySymbol: "ރ.",
  },
  {
    countryCode: "MW",
    label: "Malawi",
    value: "MWK",
    population: "15447500",
    capital: "Lilongwe",
    continentName: "Africa",
    currencySymbol: "MK",
  },
  {
    countryCode: "MX",
    label: "Mexico",
    value: "MXN",
    population: "112468855",
    capital: "Mexico City",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "MY",
    label: "Malaysia",
    value: "MYR",
    population: "28274729",
    capital: "Kuala Lumpur",
    continentName: "Asia",
    currencySymbol: "RM",
  },
  {
    countryCode: "MZ",
    label: "Mozambique",
    value: "MZN",
    population: "22061451",
    capital: "Maputo",
    continentName: "Africa",
    currencySymbol: "MT",
  },
  {
    countryCode: "NA",
    label: "Namibia",
    value: "NAD",
    population: "2128471",
    capital: "Windhoek",
    continentName: "Africa",
    currencySymbol: "$",
  },
  {
    countryCode: "NC",
    label: "New Caledonia",
    value: "XPF",
    population: "216494",
    capital: "Nouméa",
    continentName: "Oceania",
    currencySymbol: "Fr",
  },
  {
    countryCode: "NE",
    label: "Niger",
    value: "XOF",
    population: "15878271",
    capital: "Niamey",
    continentName: "Africa",
    currencySymbol: "CFA",
  },
  {
    countryCode: "NF",
    label: "Norfolk Island",
    value: "AUD",
    population: "1828",
    capital: "Kingston",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "NG",
    label: "Nigeria",
    value: "NGN",
    population: "154000000",
    capital: "Abuja",
    continentName: "Africa",
    currencySymbol: "₦",
  },
  {
    countryCode: "NI",
    label: "Nicaragua",
    value: "NIO",
    population: "5995928",
    capital: "Managua",
    continentName: "North America",
    currencySymbol: "C$",
  },
  {
    countryCode: "NL",
    label: "Netherlands",
    value: "EUR",
    population: "16645000",
    capital: "Amsterdam",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "NO",
    label: "Norway",
    value: "NOK",
    population: "5009150",
    capital: "Oslo",
    continentName: "Europe",
    currencySymbol: "kr",
  },
  {
    countryCode: "NP",
    label: "Nepal",
    value: "NPR",
    population: "28951852",
    capital: "Kathmandu",
    continentName: "Asia",
    currencySymbol: "₨",
  },
  {
    countryCode: "NR",
    label: "Nauru",
    value: "AUD",
    population: "10065",
    capital: "Yaren",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "NU",
    label: "Niue",
    value: "NZD",
    population: "2166",
    capital: "Alofi",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "NZ",
    label: "New Zealand",
    value: "NZD",
    population: "4252277",
    capital: "Wellington",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "OM",
    label: "Oman",
    value: "OMR",
    population: "2967717",
    capital: "Muscat",
    continentName: "Asia",
    currencySymbol: "﷼",
  },
  {
    countryCode: "PA",
    label: "Panama",
    value: "PAB",
    population: "3410676",
    capital: "Panama City",
    continentName: "North America",
    currencySymbol: "B/.",
  },
  {
    countryCode: "PE",
    label: "Peru",
    value: "PEN",
    population: "29907003",
    capital: "Lima",
    continentName: "South America",
    currencySymbol: "S/.",
  },
  {
    countryCode: "PF",
    label: "French Polynesia",
    value: "XPF",
    population: "270485",
    capital: "Papeete",
    continentName: "Oceania",
    currencySymbol: "Fr",
  },
  {
    countryCode: "PG",
    label: "Papua New Guinea",
    value: "PGK",
    population: "6064515",
    capital: "Port Moresby",
    continentName: "Oceania",
    currencySymbol: "K",
  },
  {
    countryCode: "PH",
    label: "Philippines",
    value: "PHP",
    population: "99900177",
    capital: "Manila",
    continentName: "Asia",
    currencySymbol: "₱",
  },
  {
    countryCode: "PK",
    label: "Pakistan",
    value: "PKR",
    population: "184404791",
    capital: "Islamabad",
    continentName: "Asia",
    currencySymbol: "₨",
  },
  {
    countryCode: "PL",
    label: "Poland",
    value: "PLN",
    population: "38500000",
    capital: "Warsaw",
    continentName: "Europe",
    currencySymbol: "zł",
  },
  {
    countryCode: "PM",
    label: "Saint Pierre and Miquelon",
    value: "EUR",
    population: "7012",
    capital: "Saint-Pierre",
    continentName: "North America",
    currencySymbol: "€",
  },
  {
    countryCode: "PN",
    label: "Pitcairn Islands",
    value: "NZD",
    population: "46",
    capital: "Adamstown",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "PR",
    label: "Puerto Rico",
    value: "USD",
    population: "3916632",
    capital: "San Juan",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "PS",
    label: "Palestine",
    value: "ILS",
    population: "3800000",
    capital: "Ramallah",
    continentName: "Asia",
    currencySymbol: "₪",
  },
  {
    countryCode: "PT",
    label: "Portugal",
    value: "EUR",
    population: "10676000",
    capital: "Lisbon",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "PW",
    label: "Palau",
    value: "USD",
    population: "19907",
    capital: "Ngerulmud",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "PY",
    label: "Paraguay",
    value: "PYG",
    population: "6375830",
    capital: "Asunción",
    continentName: "South America",
    currencySymbol: "₲",
  },
  {
    countryCode: "QA",
    label: "Qatar",
    value: "QAR",
    population: "840926",
    capital: "Doha",
    continentName: "Asia",
    currencySymbol: "﷼",
  },
  {
    countryCode: "RE",
    label: "Réunion",
    value: "EUR",
    population: "776948",
    capital: "Saint-Denis",
    continentName: "Africa",
    currencySymbol: "€",
  },
  {
    countryCode: "RO",
    label: "Romania",
    value: "RON",
    population: "21959278",
    capital: "Bucharest",
    continentName: "Europe",
    currencySymbol: "lei",
  },
  {
    countryCode: "RS",
    label: "Serbia",
    value: "RSD",
    population: "7344847",
    capital: "Belgrade",
    continentName: "Europe",
    currencySymbol: "дин.",
  },
  {
    countryCode: "RU",
    label: "Russia",
    value: "RUB",
    population: "140702000",
    capital: "Moscow",
    continentName: "Europe",
    currencySymbol: "руб",
  },
  {
    countryCode: "RW",
    label: "Rwanda",
    value: "RWF",
    population: "11055976",
    capital: "Kigali",
    continentName: "Africa",
    currencySymbol: "Fr",
  },
  {
    countryCode: "SA",
    label: "Saudi Arabia",
    value: "SAR",
    population: "25731776",
    capital: "Riyadh",
    continentName: "Asia",
    currencySymbol: "﷼",
  },
  {
    countryCode: "SB",
    label: "Solomon Islands",
    value: "SBD",
    population: "559198",
    capital: "Honiara",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "SC",
    label: "Seychelles",
    value: "SCR",
    population: "88340",
    capital: "Victoria",
    continentName: "Africa",
    currencySymbol: "₨",
  },
  {
    countryCode: "SD",
    label: "Sudan",
    value: "SDG",
    population: "35000000",
    capital: "Khartoum",
    continentName: "Africa",
    currencySymbol: "ج.س.",
  },
  {
    countryCode: "SE",
    label: "Sweden",
    value: "SEK",
    population: "9828655",
    capital: "Stockholm",
    continentName: "Europe",
    currencySymbol: "kr",
  },
  {
    countryCode: "SG",
    label: "Singapore",
    value: "SGD",
    population: "4701069",
    capital: "Singapore",
    continentName: "Asia",
    currencySymbol: "$",
  },
  {
    countryCode: "SH",
    label: "Saint Helena",
    value: "SHP",
    population: "7460",
    capital: "Jamestown",
    continentName: "Africa",
    currencySymbol: "£",
  },
  {
    countryCode: "SI",
    label: "Slovenia",
    value: "EUR",
    population: "2007000",
    capital: "Ljubljana",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "SJ",
    label: "Svalbard and Jan Mayen",
    value: "NOK",
    population: "2550",
    capital: "Longyearbyen",
    continentName: "Europe",
    currencySymbol: "kr",
  },
  {
    countryCode: "SK",
    label: "Slovakia",
    value: "EUR",
    population: "5455000",
    capital: "Bratislava",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "SL",
    label: "Sierra Leone",
    value: "SLL",
    population: "5245695",
    capital: "Freetown",
    continentName: "Africa",
    currencySymbol: "Le",
  },
  {
    countryCode: "SM",
    label: "San Marino",
    value: "EUR",
    population: "31477",
    capital: "San Marino",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "SN",
    label: "Senegal",
    value: "XOF",
    population: "12323252",
    capital: "Dakar",
    continentName: "Africa",
    currencySymbol: "CFA",
  },
  {
    countryCode: "SO",
    label: "Somalia",
    value: "SOS",
    population: "10112453",
    capital: "Mogadishu",
    continentName: "Africa",
    currencySymbol: "Sh",
  },
  {
    countryCode: "SR",
    label: "Suriname",
    value: "SRD",
    population: "492829",
    capital: "Paramaribo",
    continentName: "South America",
    currencySymbol: "$",
  },
  {
    countryCode: "SS",
    label: "South Sudan",
    value: "SSP",
    population: "8260490",
    capital: "Juba",
    continentName: "Africa",
    currencySymbol: "£",
  },
  {
    countryCode: "ST",
    label: "São Tomé and Príncipe",
    value: "STD",
    population: "175808",
    capital: "São Tomé",
    continentName: "Africa",
    currencySymbol: "Db",
  },
  {
    countryCode: "SV",
    label: "El Salvador",
    value: "USD",
    population: "6052064",
    capital: "San Salvador",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "SX",
    label: "Sint Maarten",
    value: "ANG",
    population: "37429",
    capital: "Philipsburg",
    continentName: "North America",
    currencySymbol: "ƒ",
  },
  {
    countryCode: "SY",
    label: "Syria",
    value: "SYP",
    population: "22198110",
    capital: "Damascus",
    continentName: "Asia",
    currencySymbol: "£",
  },
  {
    countryCode: "SZ",
    label: "Eswatini",
    value: "SZL",
    population: "1354051",
    capital: "Mbabane",
    continentName: "Africa",
    currencySymbol: "L",
  },
  {
    countryCode: "TC",
    label: "Turks and Caicos Islands",
    value: "USD",
    population: "20556",
    capital: "Cockburn Town",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "TD",
    label: "Chad",
    value: "XAF",
    population: "10543464",
    capital: "N'Djamena",
    continentName: "Africa",
    currencySymbol: "CFA",
  },
  {
    countryCode: "TF",
    label: "French Southern Territories",
    value: "EUR",
    population: "140",
    capital: "Port-aux-Français",
    continentName: "Antarctica",
    currencySymbol: "€",
  },
  {
    countryCode: "TG",
    label: "Togo",
    value: "XOF",
    population: "6587239",
    capital: "Lomé",
    continentName: "Africa",
    currencySymbol: "CFA",
  },
  {
    countryCode: "TH",
    label: "Thailand",
    value: "THB",
    population: "67089500",
    capital: "Bangkok",
    continentName: "Asia",
    currencySymbol: "฿",
  },
  {
    countryCode: "TJ",
    label: "Tajikistan",
    value: "TJS",
    population: "7487489",
    capital: "Dushanbe",
    continentName: "Asia",
    currencySymbol: "ЅМ",
  },
  {
    countryCode: "TK",
    label: "Tokelau",
    value: "NZD",
    population: "1466",
    capital: "",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "TL",
    label: "East Timor",
    value: "USD",
    population: "1154625",
    capital: "Dili",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "TM",
    label: "Turkmenistan",
    value: "TMT",
    population: "4940916",
    capital: "Ashgabat",
    continentName: "Asia",
    currencySymbol: "m",
  },
  {
    countryCode: "TN",
    label: "Tunisia",
    value: "TND",
    population: "10589025",
    capital: "Tunis",
    continentName: "Africa",
    currencySymbol: "د.ت",
  },
  {
    countryCode: "TO",
    label: "Tonga",
    value: "TOP",
    population: "122580",
    capital: "Nuku'alofa",
    continentName: "Oceania",
    currencySymbol: "T$",
  },
  {
    countryCode: "TR",
    label: "Turkey",
    value: "TRY",
    population: "77804122",
    capital: "Ankara",
    continentName: "Asia",
    currencySymbol: "₺",
  },
  {
    countryCode: "TT",
    label: "Trinidad and Tobago",
    value: "TTD",
    population: "1228691",
    capital: "Port of Spain",
    continentName: "North America",
    currencySymbol: "TT$",
  },
  {
    countryCode: "TV",
    label: "Tuvalu",
    value: "AUD",
    population: "10472",
    capital: "Funafuti",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "TW",
    label: "Taiwan",
    value: "TWD",
    population: "22894384",
    capital: "Taipei",
    continentName: "Asia",
    currencySymbol: "NT$",
  },
  {
    countryCode: "TZ",
    label: "Tanzania",
    value: "TZS",
    population: "41892895",
    capital: "Dodoma",
    continentName: "Africa",
    currencySymbol: "Sh",
  },
  {
    countryCode: "UA",
    label: "Ukraine",
    value: "UAH",
    population: "45415596",
    capital: "Kyiv",
    continentName: "Europe",
    currencySymbol: "₴",
  },
  {
    countryCode: "UG",
    label: "Uganda",
    value: "UGX",
    population: "33398682",
    capital: "Kampala",
    continentName: "Africa",
    currencySymbol: "USh",
  },
  {
    countryCode: "UM",
    label: "U.S. Outlying Islands",
    value: "USD",
    population: "0",
    capital: "",
    continentName: "Oceania",
    currencySymbol: "$",
  },
  {
    countryCode: "US",
    label: "United States",
    value: "USD",
    population: "310232863",
    capital: "Washington",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "UY",
    label: "Uruguay",
    value: "UYU",
    population: "3477000",
    capital: "Montevideo",
    continentName: "South America",
    currencySymbol: "$",
  },
  {
    countryCode: "UZ",
    label: "Uzbekistan",
    value: "UZS",
    population: "27865738",
    capital: "Tashkent",
    continentName: "Asia",
    currencySymbol: "лв",
  },
  {
    countryCode: "VA",
    label: "Vatican City",
    value: "EUR",
    population: "921",
    capital: "Vatican City",
    continentName: "Europe",
    currencySymbol: "€",
  },
  {
    countryCode: "VC",
    label: "Saint Vincent and the Grenadines",
    value: "XCD",
    population: "104217",
    capital: "Kingstown",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "VE",
    label: "Venezuela",
    value: "VEF",
    population: "27223228",
    capital: "Caracas",
    continentName: "South America",
    currencySymbol: "Bs",
  },
  {
    countryCode: "VG",
    label: "British Virgin Islands",
    value: "USD",
    population: "21730",
    capital: "Road Town",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "VI",
    label: "U.S. Virgin Islands",
    value: "USD",
    population: "108708",
    capital: "Charlotte Amalie",
    continentName: "North America",
    currencySymbol: "$",
  },
  {
    countryCode: "VN",
    label: "Vietnam",
    value: "VND",
    population: "89571130",
    capital: "Hanoi",
    continentName: "Asia",
    currencySymbol: "₫",
  },
  {
    countryCode: "VU",
    label: "Vanuatu",
    value: "VUV",
    population: "221552",
    capital: "Port Vila",
    continentName: "Oceania",
    currencySymbol: "Vt",
  },
  {
    countryCode: "WF",
    label: "Wallis and Futuna",
    value: "XPF",
    population: "16025",
    capital: "Mata-Utu",
    continentName: "Oceania",
    currencySymbol: "₣",
  },
  {
    countryCode: "WS",
    label: "Samoa",
    value: "WST",
    population: "192001",
    capital: "Apia",
    continentName: "Oceania",
    currencySymbol: "T",
  },
  {
    countryCode: "YE",
    label: "Yemen",
    value: "YER",
    population: "23495361",
    capital: "Sanaa",
    continentName: "Asia",
    currencySymbol: "﷼",
  },
  {
    countryCode: "YT",
    label: "Mayotte",
    value: "EUR",
    population: "159042",
    capital: "Mamoudzou",
    continentName: "Africa",
    currencySymbol: "€",
  },
  {
    countryCode: "ZA",
    label: "South Africa",
    value: "ZAR",
    population: "49000000",
    capital: "Pretoria",
    continentName: "Africa",
    currencySymbol: "R",
  },
  {
    countryCode: "ZM",
    label: "Zambia",
    value: "ZMK",
    population: "13460305",
    capital: "Lusaka",
    continentName: "Africa",
    currencySymbol: "ZK",
  },
  {
    countryCode: "ZW",
    label: "Zimbabwe",
    value: "ZWL",
    population: "11651858",
    capital: "Harare",
    continentName: "Africa",
    currencySymbol: "Z$",
  },
];

export const Currencies = (data) => {
  let result = _.uniq(_.map(currencylists, (item) => item.value))?.filter(
    (item) => item !== ""
  );

  let currencyWithSymbols = _.reduce(
    currencylists,
    (acc, item) => {
      acc[item.value] = item.currencySymbol;
      return acc;
    },
    {}
  );

  return data?.currencySymbol ? currencyWithSymbols : result;
};

export const parseFilters = (input) => {
  const result = input
    ?.split(";")
    ?.map((pair) => pair.split("=")[1])
    .filter(Boolean)
    .join(" & ");

  return result || "users"; // Return 'users' if result is empty
};
