import React, { useEffect, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import useStore from "../../../Store";
import MyLoader from "../../MiroComponents/PreLoader";
import { Pagination } from "../../Shared/Paginations";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "../../MiroComponents/Button/Button";
import {
  DOWNLOADREPORT,
  GETALLREPORTFORCLIENTID,
} from "../../../Graphql/Queries";

function ReportReview() {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const selectedClient = useStore((state) => state.selectedClient);
  const [downloadingFileId, setDownloadingFileId] = useState(null);
  const {
    data,
    loading,
    error: reviewsError,
    refetch,
  } = useQuery(GETALLREPORTFORCLIENTID, {
    variables: {
      clientId: selectedClient?.value?._id,
      page: page,
      size: size,
    },
  });

  // 🛠️ Use Lazy Query for Downloading File on Click
  const [fetchDownload] = useLazyQuery(DOWNLOADREPORT);

  useEffect(() => {
    if (selectedClient?.value?._id) {
      refetch();
    }
  }, [selectedClient, refetch]);

  useEffect(() => {
    if (location?.state?.page) {
      setPage(location?.state?.page);
    }
  }, [location]);

  const handleDownload = async (fileId) => {
    setDownloadingFileId(fileId);
    try {
      const { data } = await fetchDownload({ variables: { id: fileId } });

      if (!data || !data.downloadFile) {
        toast.error("Failed to download file");
        return;
      }

      const { filename, mimetype, data: base64Data } = data.downloadFile;
      downloadBase64File(base64Data, filename, mimetype);
    } catch (error) {
      console.log("Download error:", error);
      toast.error("Error downloading the file");
    } finally {
      setDownloadingFileId(null);
    }
  };

  function downloadBase64File(base64, filename, mimetype) {
    const link = document.createElement("a");
    link.href = `data:${mimetype};base64,${base64}`;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  if (reviewsError) {
    toast.error("Error in fetching reports");
  }

  return (
    <div>
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2> Review Reports Dashboard</h2>
        </div>
      </div>

      {loading ? (
        <MyLoader />
      ) : (
        <div className="table-body">
          <div className="test-details-table-data heading-row">
            <div
              className="hypothesis-data"
              style={{
                maxWidth: "50%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span>File name</span>
            </div>
            <div
              className="test-name"
              style={{
                maxWidth: "50%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h3>Download</h3>
            </div>
          </div>

          {data?.getAllFilesByClientID?.length ? (
            data.getAllFilesByClientID.map((item, i) => (
              <div
                key={i}
                className="test-details-table-data"
                style={{ justifyContent: "start" }}
              >
                <div style={{ maxWidth: "50%" }} className="hypothesis-data">
                  {item?.filename}
                </div>
                <div
                  className="test-name"
                  style={{
                    maxWidth: "50%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    className="smart-button small active"
                    onClick={() => handleDownload(item?._id)}
                    disabled={downloadingFileId === item?._id}
                  >
                    {downloadingFileId === item?._id
                      ? "Downloading..."
                      : "Download"}
                  </Button>
                </div>
              </div>
            ))
          ) : (
            <div className="table-no-data">No Records</div>
          )}
        </div>
      )}

      <Pagination
        page={page}
        size={size}
        setPage={setPage}
        setSize={setSize}
        totalcount={data?.getAllFilesByClientID?.total}
      />
    </div>
  );
}

export default ReportReview;
