import { useParams } from "react-router-dom";
import { Button } from "../../MiroComponents/Button/Button";
import { ReportIcon } from "../../Shared/Icons";
import { GETREPORTV2 } from "../../../Graphql/Queries";
import { useMutation, useQuery } from "@apollo/client";
import MyLoader from "../../MiroComponents/PreLoader";
import React, { useMemo } from "react";
import { checkConclusion } from "../../../Libs/Utilities";
import ReportTable from "../../NewComponents/ReportTable/ReportTable";
import {
  EXCLUDEVARIANTFROMREPORT,
  FORCEWINNER,
} from "../../../Graphql/Mutations";
import NoDataFound from "../../MiroComponents/NoDataFound/NoDataFound";

export const ClientReportsAdminManageReport = () => {
  const { _id } = useParams();
  const [excludevariant] = useMutation(EXCLUDEVARIANTFROMREPORT);
  const [forceVariantWinner] = useMutation(FORCEWINNER);

  const { data, loading, error, refetch } = useQuery(GETREPORTV2, {
    variables: {
      getReportV2Id: _id,
    },
  });
  const toggleVariant = async (variant) => {
    await excludevariant({
      variables: {
        id: _id,
        variant,
      },
    });
    refetch();
  };
  const toggleForceWinner = async (value) => {
    await forceVariantWinner({
      variables: {
        id: _id,
        value,
      },
    });
    refetch();
  };

  const isMabTest = useMemo(() => {
    if (
      data?.getReportV2[0]?.test?.tags?.some((tag) => tag?.name === "MAB Test")
    )
      return true;
    return false;
  }, [data]);
  if (loading) return <MyLoader />;
  if (error)
    return (
      <NoDataFound title="Error fetching report" description={error?.message} />
    );
  const report = data?.getReportV2[0];
  return (
    <div className="">
      <div className="report-name-heading flex align-center justify-space-between flex-wrap">
        <h1 className="m-0">
          {report.test?.name || "Report Details"} ({report?.segmentName})
        </h1>
        <div>
          <Button
            onClick={(e) => {
              e.preventDefault();
              if (_id) {
                window.open(
                  `${process.env.REACT_APP_CLIENT_APP_URL}/sharereport/${_id}`,
                  "_blank"
                );
              }
            }}
            text={`View Report`}
            className={`smart-button big uppercase full-width`}
            icon={<ReportIcon />}
          />
        </div>
      </div>
      {report?.reportDetails?.conclusionFactorStatusAll && !isMabTest && (
        <div className="conclusion-factors-wrapper default-column-gap inline-flex align-center default-margin-top default-margin-bottom">
          {Object.keys(report?.reportDetails?.conclusionFactorStatusAll).map(
            (key) => (
              <React.Fragment key={key}>
                <Button className="smart-pill small">
                  {key.replaceAll("Change in", "")}:{" "}
                  {checkConclusion(
                    report?.reportDetails?.conclusionFactorStatusAll[
                      key
                    ]?.toLowerCase()
                  )}
                </Button>
                <div className="default-margin-top default-margin-bottom">
                  <div className="switch-with-label inline-flex align-center">
                    <div className="switch-btn">
                      <input
                        onChange={(e) => {
                          const value = key
                            .replaceAll("Change in", "")
                            ?.trim()
                            ?.toUpperCase();
                          toggleForceWinner(value);
                        }}
                        type="checkbox"
                        defaultChecked={
                          report?.reportDetails?.forcedWinner ===
                            key
                              .replaceAll("Change in", "")
                              ?.trim()
                              ?.toUpperCase() || false
                        }
                        className="checkbox"
                        id={`${key}`}
                      />
                      <label htmlFor={`${key}`} className="checkbox-label">
                        <div>yes</div>
                        <div>no</div>
                        <span className="ball"></span>
                      </label>
                    </div>
                    <span>Force Winner</span>
                  </div>
                </div>
              </React.Fragment>
            )
          )}
        </div>
      )}

      {!isMabTest && (
        <ReportTable
          total={[report?.reportDetails?.totalOfResults]}
          controlVariantData={report?.reportDetails?.result}
          excludedVariants={report?.reportDetails?.excludedVariants}
          //   manageVariant={true}
          toggleVariant={toggleVariant}
        />
      )}
    </div>
  );
};
