import React, { useState } from "react";
import { ABCompareIcon, CheckBoxMarkIcon, FilterIcon } from "../Shared/Icons";
import { Scrubber } from "../Shared/Scrubber";
import { DateRangeSelectorComponent } from "../Shared/DateRangeSelectorComponent";
import { ReportFilterValues } from "../../Libs/ReportFiltersValues";
import Select from "react-select";
import { Currencies, OperatorValues } from "../../Libs/Utilities";
import { Button } from "../MiroComponents/Button/Button";

const inputFilters = [
  {
    label: "Purchase revenue",
    value: "purchaseRevenue",
  },
  {
    label: "Products",
    value: "products",
  },
  {
    label: "Transaction IDs",
    value: "transactionIds",
  },
  {
    label: "Buyers with products not priced",
    value: "buyersWithProductsNotPriced",
  },
];

export function AnalyticsDimensionsAndMetrics({
  client,
  viewId,
  selectedClient,
  platform,
  selectedTest,
  showCalendar,
  setShowCalendar,
  selectionRange,
  setSelectionRange,
  getreport,
  callBackWhenClose,
  createDimensionFilters,
  dimensionFilterExpression,
  removeSegment,
  includeProducts = false,
  setIncludeProducts = () => void 0,
  interaDayTable = false,
  useRegexForUserId = false,
  selectedCurrency = null,
  showQuery = false,
  setShowQuery = () => void 0,
  setSelectedCurrency = () => void 0,
  setUseRegexForUserId = () => void 0,
  setInteraDayTable = () => void 0,
  file = null,
  setFile = () => void 0,
}) {
  const [scrubber, showScrubber] = useState(null);
  const [valueFilters, setValueFilters] = useState({
    value: null,
    key: null,
    filterType: "string",
    matchType: null,
  });

  const getSelectValue = (selectedSegments, values) => {
    if (!selectedSegments) return null;
    return {
      label: values?.find(
        (val) =>
          val?.toLowerCase() ===
          selectedSegments?.filter?.stringFilter?.value?.toLowerCase()
      ),
      value: selectedSegments?.filter?.stringFilter?.value,
    };
  };

  if (!client || !viewId || !selectedTest) return ``;
  if (platform === "BQ" && !selectedClient?.bqClientId)
    return (
      <div>
        <h3
          style={{
            color: "#fff",
          }}
        >
          No Big Query ClientID found for this property.
        </h3>
      </div>
    );
  return (
    <>
      <Scrubber showScrubber={showScrubber} scrubber={scrubber} />
      <section className="selected-test-content live-reports-content">
        <div className="experiment-data-inner">
          <div className="d-flex justify-content-between align-center">
            <h1 className="">Experiment Data</h1>
            {/* filter icon for mobile screens as general filters will be removed in mobile view */}
            <div className="reporting-mobile-filters">
              <FilterIcon />
              <span className="reporting-mobile-filters__text">Filter</span>
            </div>
            <div
              className="ab-compare"
              onClick={(e) => {
                showScrubber({
                  control: selectedTest?.controlimage,
                  variation: selectedTest?.variationimage,
                });
              }}
            >
              <ABCompareIcon />
              <span>Compare</span>
            </div>
          </div>
          <div className="experiment-data-navbar">
            <div className="experiment-data-dropdown-pane properties-dropdown">
              {ReportFilterValues?.map((filter, i) => {
                return (
                  <div className="experiment-dropdown-item" key={i}>
                    <span className="experiment-dropdown-text">
                      <Select
                        placeholder={filter?.label}
                        value={getSelectValue(
                          dimensionFilterExpression?.find(
                            (val) =>
                              val?.filter?.fieldName === filter?.fieldName
                          ),
                          filter?.values
                        )}
                        onChange={(val) => {
                          createDimensionFilters(
                            val,
                            filter?.fieldName,
                            filter?.filterType,
                            filter?.matchType
                          );
                          // selectSegments(
                          //   segment?.key,
                          //   val,
                          //   selectedSegments,
                          //   "=="
                          // );
                        }}
                        // menuIsOpen={true}
                        // isDisabled={platform === "BQ"}
                        className="acc-newselectwrapper"
                        classNamePrefix="acc-newselect"
                        options={filter?.values?.map((val) => {
                          // console.log({
                          //   label: val,
                          //   value: val?.toLowerCase(),
                          // });
                          return {
                            label: val,
                            value: val?.toLowerCase(),
                          };
                        })}
                      />
                    </span>
                  </div>
                );
              })}
              {/* <div className="experiment-dropdown-item">
                <span className="experiment-dropdown-text">
                  <Select
                    placeholder={`Select Currency`}
                    value={selectedCurrency}
                    onChange={(val) => {
                      if (!val?.value) {
                        setSelectedCurrency(null);
                        return;
                      }
                      setSelectedCurrency(val);
                    }}
                    className="acc-newselectwrapper"
                    classNamePrefix="acc-newselect"
                    options={[
                      {
                        label: "Reset",
                        value: null,
                      },
                      {
                        label: "USD",
                        value: "USD",
                      },
                      {
                        label: "EUR",
                        value: "EUR",
                      },
                      {
                        label: "GBP",
                        value: "GBP",
                      },
                    ]}
                  />
                </span>
              </div> */}
              <div className="experiment-dropdown-item">
                <span className="experiment-dropdown-text">
                  <div className="checkbox-wrapper-4">
                    <input
                      className="inp-cbx"
                      id="revenueToUSD"
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          createDimensionFilters(
                            {
                              label: "USD",
                              value: "usd",
                            },
                            "revenueCurrency",
                            "string",
                            "EXACT"
                          );
                        } else {
                          removeSegment("revenueCurrency");
                        }
                      }}
                      checked={
                        getSelectValue(
                          dimensionFilterExpression?.find(
                            (val) =>
                              val?.filter?.fieldName === "revenueCurrency"
                          ),
                          ["USD"]
                        )?.value === "usd"
                          ? true
                          : false
                      }
                    />
                    <label className="cbx" htmlFor="revenueToUSD">
                      <span>
                        <CheckBoxMarkIcon />
                      </span>
                      <span>Convert Revenue to USD</span>
                    </label>
                    {/* <svg className="inline-svg">
                      <symbol id="check-4" viewBox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                      </symbol>
                    </svg> */}
                  </div>
                </span>
              </div>
              <div className="experiment-dropdown-item">
                <span className="experiment-dropdown-text">
                  <div className="checkbox-wrapper-4">
                    <input
                      className="inp-cbx"
                      id="interaDayTable"
                      type="checkbox"
                      onChange={(e) => {
                        setInteraDayTable(e.target.checked);
                      }}
                      checked={interaDayTable}
                    />
                    <label className="cbx" htmlFor="interaDayTable">
                      <span>
                        <CheckBoxMarkIcon />
                      </span>
                      <span>Use Intra Day Table</span>
                    </label>
                    {/* <svg className="inline-svg">
                      <symbol id="check-4" viewBox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                      </symbol>
                    </svg> */}
                  </div>
                </span>
              </div>

              <div className="experiment-dropdown-item">
                <span className="experiment-dropdown-text">
                  <div className="checkbox-wrapper-4">
                    <input
                      className="inp-cbx"
                      id="useRegexForUserId"
                      type="checkbox"
                      onChange={(e) => {
                        setUseRegexForUserId(e.target.checked);
                      }}
                      checked={useRegexForUserId}
                    />
                    <label className="cbx" htmlFor="useRegexForUserId">
                      <span>
                        <CheckBoxMarkIcon />
                      </span>
                      <span>use Regex For UserIds</span>
                    </label>
                  </div>
                </span>
              </div>
              <div className="experiment-dropdown-item">
                <span className="experiment-dropdown-text">
                  <div className="checkbox-wrapper-4">
                    <input
                      className="inp-cbx"
                      id="showQuery"
                      type="checkbox"
                      onChange={(e) => {
                        setShowQuery(e.target.checked);
                      }}
                      checked={showQuery}
                    />
                    <label className="cbx" htmlFor="showQuery">
                      <span>
                        <CheckBoxMarkIcon />
                      </span>
                      <span>Show Raw Query</span>
                    </label>
                  </div>
                </span>
              </div>
              <div className="experiment-dropdown-item">
                <span className="experiment-dropdown-text">
                  <div className="checkbox-wrapper-4">
                    <input
                      className="inp-cbx"
                      id="includeProducts"
                      type="checkbox"
                      checked={includeProducts}
                      onChange={(e) => {
                        setIncludeProducts(e.target.checked);
                      }}
                    />
                    <label className="cbx" htmlFor="includeProducts">
                      <span>
                        <CheckBoxMarkIcon />
                      </span>
                      <span>Fetch Products to calculate profit (BETA)</span>
                    </label>
                    {/* <svg className="inline-svg">
                      <symbol id="check-4" viewBox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                      </symbol>
                    </svg> */}
                  </div>
                </span>
              </div>

              {includeProducts && (
                <div className="xls-sheet-wrapper">
                  <div className="upload-btn-wrapper">
                    <button className="btn">
                      {file?.name ? file?.name : "Upload a file"}
                    </button>
                    <input
                      type="file"
                      onChange={(e) => setFile(e.target.files[0])}
                      name="myfile"
                    />
                  </div>
                  <a
                    href="https://docs.google.com/spreadsheets/d/1b_Za99BXhgvEUN9k4PmVGbXDNbcgxTOne1gcY3QbUpc/edit?usp=sharing"
                    target="_blank"
                  >
                    <span>Download Sample File</span>
                  </a>
                </div>
              )}

              {/* {segmentOptions?.map((segment, i) => (
                  <div className="experiment-dropdown-item" key={i}>
                    <span className="experiment-dropdown-text">
                      <Select
                        placeholder={segment?.name}
                        className="acc-newselectwrapper"
                        value={getSelectValue(segment?.key, selectedSegments)}
                        onChange={(val) => {
                          selectSegments(
                            segment?.key,
                            val,
                            selectedSegments,
                            "=="
                          );
                        }}
                        classNamePrefix="acc-newselect"
                        options={segment?.values?.map((val) => {
                          return {
                            label: val,
                            value: val,
                          };
                        })}
                      />
                    </span>
                  </div>
                ))} */}
            </div>
            <div className="date-selector">
              <DateRangeSelectorComponent
                showCalendar={showCalendar}
                setShowCalendar={setShowCalendar}
                selectionRange={selectionRange}
                setSelectionRange={setSelectionRange}
                className="dark-calendar"
                classNameWrapper="dark-calendar-wrapper"
                callBackWhenClose={callBackWhenClose}
                showDays={true}
                color="#fff"
              />
            </div>
          </div>
          <div className="experiment-segment">
            <div className="segment-data align-center">
              <div className="segment-dropdown-value">
                {platform === "BQ" && (
                  <>
                    <div className="experiment-dropdown-text">
                      <Select
                        className="acc-newselectwrapper"
                        placeholder="Segment"
                        value={inputFilters.find(
                          (val) => val?.value === valueFilters?.key
                        )}
                        onChange={(val) => {
                          setValueFilters((oldValue) => ({
                            ...oldValue,
                            key: val?.value,
                          }));
                        }}
                        classNamePrefix="acc-newselect"
                        options={inputFilters}
                      />
                    </div>
                    <div className="experiment-dropdown-text">
                      <Select
                        className="acc-newselectwrapper"
                        placeholder="Equals to"
                        value={OperatorValues({
                          key: valueFilters?.key,
                        }).find(
                          (val) => val?.value === valueFilters?.matchType
                        )}
                        onChange={(val) => {
                          setValueFilters((oldValue) => ({
                            ...oldValue,
                            matchType: val?.value,
                          }));
                        }}
                        classNamePrefix="acc-newselect"
                        options={OperatorValues({
                          key: valueFilters?.key,
                        })}
                      />
                    </div>
                    <div className="segment-input">
                      <input
                        type="text"
                        id="segmentValue"
                        defaultValue={""}
                        placeholder="Value..."
                        className="segment-search"
                      />
                    </div>
                    <div className="segment-button">
                      <button
                        onClick={(e) => {
                          const values = document
                            .querySelector("#segmentValue")
                            .value.trim();
                          if (
                            valueFilters?.key &&
                            valueFilters?.matchType &&
                            values !== ""
                          ) {
                            createDimensionFilters(
                              {
                                label: values,
                                value: values,
                              },
                              valueFilters?.key,
                              valueFilters?.filterType,
                              valueFilters?.matchType
                            );
                            document.querySelector("#segmentValue").value = "";
                          }
                        }}
                      >
                        {" "}
                        Add
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="segment-btn">
                <Button
                  className="smart-button full-width big active"
                  onClick={(e) => {
                    e.preventDefault();
                    getreport();
                  }}
                >
                  Live Reports
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export const FilterAnalyticsDimensionsAndMetrics = React.memo(
  AnalyticsDimensionsAndMetrics
);
