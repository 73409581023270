import React, { useState, useMemo } from "react";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import MyLoader from "./PreLoader";
import { Button } from "./Button/Button";
import {
  DefaultCrewAiJson,
  DefaultScrapingAndClassificationJson,
  QueueTaskType,
  scheduleScraping,
} from "../../Libs/Constants";
import { TimesCross } from "../Shared/Icons";

const MsgMiningDialog = ({ client, refetchReviews }) => {
  const [state, setState] = useState({
    selectedOption: null,
    textValue: JSON.stringify(DefaultScrapingAndClassificationJson, null, 2),
    days: null,
    showForm: false,
    loading: false,
  });

  const defaultConfigs = useMemo(
    () => ({
      web_scraping: DefaultScrapingAndClassificationJson,
      crewai_mm: DefaultCrewAiJson,
    }),
    []
  );

  const handleSelectChange = (option) => {
    setState((prev) => ({
      ...prev,
      selectedOption: option,
      textValue: JSON.stringify(defaultConfigs[option.value] || {}, null, 2),
    }));
  };

  const toggleForm = () => {
    setState((prev) => ({
      ...prev,
      showForm: !prev.showForm,
      selectedOption: null,
      textValue: JSON.stringify(DefaultScrapingAndClassificationJson, null, 2),
      days: null,
      loading: false,
    }));
  };

  const generateSlug = (name) =>
    name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-|-$/g, "");

  const handleDays = (day) => {
    setState((prev) => ({ ...prev, days: day }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setState((prev) => ({ ...prev, loading: true }));

    try {
      const parsedData = JSON.parse(state.textValue);
      const uuid = uuidv4();
      const slug = generateSlug(client?.label || "task");

      const payload =
        state.selectedOption?.value === "crewai_mm"
          ? {
              type: state.selectedOption.value,
              status: "new",
              created_at: Date.now(),
              ...parsedData,
              client: client?.label,
              slug,
              id: uuid,
              scraping_frequency: state.days?.value,
              clientId: client?.value?._id,
            }
          : {
              created_at: Date.now(),
              client: client.label,
              limit_users: [],
              status: "new",
              config: { ...parsedData, id: uuid },
              clientId: client?.value?._id,
              slug,
              type: state.selectedOption?.value,
              scraping_frequency: state.days?.value,
              type_of_review: parsedData?.type_of_review,
              type_of_scarp: parsedData?.type_of_scarp,
            };

      await refetchReviews(payload);
    } catch (error) {
      toast.error(`Invalid JSON: ${error.message}`);
      console.error("Invalid JSON:", error.message);
    }

    setState((prev) => ({ ...prev, loading: false }));
    toggleForm();
  };

  return (
    <div>
      <Button className="smart-button big active" onClick={toggleForm}>
        Generate MSG Mining
      </Button>

      {state.showForm && (
        <div className="form-wrapper" id="add-new-test-form">
          <div className="form-wrapper-inner just">
            <div className="card">
              <button className="close-modal" onClick={toggleForm}>
                <TimesCross />
              </button>
              <div className="card-body">
                <h3>Generate MSG Mining</h3>
                {state.loading ? (
                  <MyLoader />
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="fields-wrapper">
                      <label>Select Task</label>
                      <Select
                        required
                        onChange={handleSelectChange}
                        value={state.selectedOption}
                        className="acc-newselectwrapper"
                        classNamePrefix="acc-newselect"
                        placeholder="Select scraper"
                        options={QueueTaskType}
                      />
                    </div>
                    <div className="fields-wrapper">
                      <label>Schedule time</label>
                      <Select
                        required
                        onChange={handleDays}
                        value={state.days}
                        className="acc-newselectwrapper"
                        classNamePrefix="acc-newselect"
                        placeholder="Select schedule"
                        options={scheduleScraping}
                      />
                    </div>
                    <div className="fields-wrapper">
                      <label>Configuration JSON</label>
                      <textarea
                        required
                        className="field-control"
                        name="textArea"
                        value={state.textValue}
                        onChange={(e) =>
                          setState((prev) => ({
                            ...prev,
                            textValue: e.target.value,
                          }))
                        }
                        rows={20}
                        style={{ resize: "vertical", fontSize: "17px" }}
                      />
                    </div>
                    <div className="fields-wrapper">
                      <Button
                        type="submit"
                        className="smart-button small active"
                        disabled={state.loading}
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MsgMiningDialog;
