import React, { useContext, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../MiroComponents/Button/Button";
import { toast } from "react-toastify";
import { ReportIcon } from "../../Shared/Icons";
import MyRoutes from "../../../Routes";
import { FaEdit } from "react-icons/fa";
export default function ReportListItem({
  type = "report",
  keys = [],
  data = [],
  currency,
  toggleIncludeExludeReport = () => {},
}) {
  const navigate = useNavigate();
  // const allowToggleReport = useMemo(() => {
  //   return (
  //     myProfile?.superadmin || myProfile?.role?.some((r) => r?.name === "ADMIN")
  //   );
  // }, [myProfile]);
  return (
    <div>
      <div className="report-data-table oddevenpattern">
        <table>
          <tbody>
            <tr className="header">
              {keys.map((key, index) => (
                <td key={index}>{key}</td>
              ))}
            </tr>
            {data.map((item, index) => (
              <tr className="data" key={index}>
                <td
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (item?._id) {
                      window.open(
                        `${process.env.REACT_APP_CLIENT_APP_URL}/sharereport/${item?._id}`,
                        "_blank"
                      );
                    }
                  }}
                >
                  {item?.controlvariant}{" "}
                  {item?.isMabTest && (
                    <Button className="smart-pill xsmall active">
                      MAB Test
                    </Button>
                  )}
                </td>
                <td>{!item?.isMabTest ? item?.conclusion : "NA"}</td>
                <td>
                  <p>{item?.segment}</p>

                  {item?.segment?.toLowerCase() === "no data" && (
                    <div className="default-margin-top">
                      <Button className="smart-button small">
                        <Link
                          className="editReport"
                          style={{
                            textDecoration: "none",
                          }}
                          to={`${MyRoutes.createmanualreport}/${item?._id}`}
                        >
                          Edit
                        </Link>
                      </Button>
                    </div>
                  )}
                </td>

                <td>{item?.duration}</td>
                <td>
                  {!item?.isMabTest ? item?.changeInConversionRate : "NA"}
                </td>

                <td>{item?.additionalRevenue} </td>
                <td>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      if (item?._id) {
                        window.open(
                          `${process.env.REACT_APP_CLIENT_APP_URL}/sharereport/${item?._id}`,
                          "_blank"
                        );
                      }
                    }}
                    text={`More Details`}
                    className={`smart-button small uppercase full-width`}
                    icon={<ReportIcon />}
                  />
                  {/* {allowToggleReport && ( */}
                  <div className="flex justify-center default-margin-top default-margin-bottom">
                    <div className="switch-with-label inline-flex align-center">
                      <div className="switch-btn">
                        <input
                          onChange={(e) => {
                            toggleIncludeExludeReport(
                              item?._id,
                              e.target.checked
                            );
                            toast.success(
                              "Please refresh the page to see changes on Performance Overview."
                            );
                          }}
                          type="checkbox"
                          defaultChecked={
                            item?.excludedFromCalculations || false
                          }
                          className="checkbox"
                          id={`${item?._id}`}
                        />
                        <label
                          htmlFor={`${item?._id}`}
                          className="checkbox-label"
                        >
                          <div>yes</div>
                          <div>no</div>
                          <span className="ball"></span>
                        </label>
                      </div>
                      <span>Exclude Report </span>
                    </div>
                  </div>
                  {/* )} */}
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      if (item?._id) {
                        navigate(
                          `${MyRoutes.clientreports}/manage/${item?._id}`
                        );
                      }
                    }}
                    text={`Manage Report`}
                    className={`smart-button small uppercase full-width`}
                    icon={<FaEdit />}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
