import useStore from "../../../Store";
import NoDataFound from "../../MiroComponents/NoDataFound/NoDataFound";
import TestsOverview from "../../NewComponents/TestsOverview/TestsOverview";
import ReportsList from "../../NewComponents/ReportsList/ReportsList";
import PerformanceOverview from "../../NewComponents/PerformanceOverview/PerformanceOverview";
// import AddRevPerMonth from "../../NewComponents/AddRevPerMonth/AddRevPerMonth";
export const ClientReportsAdmin = () => {
  const selectedClient = useStore((state) => state.selectedClient);

  if (!selectedClient) return <NoDataFound />;
  return (
    <>
      <TestsOverview client={selectedClient?.value?._id} />

      <ReportsList client={selectedClient?.value?._id} />
      {/* <ClientStrategyandRoadmap
            clientId={selectedClient?.value?._id}
            selectedProperty={viewId}
            showSection={clientStatsReports?.show}
          />
          <ClientTopLearningsandNextstep
            clientId={selectedClient?.value?._id}
            selectedProperty={viewId}
            showSection={clientStatsReports?.show}
          />

          <ClientTestsOverview
            statistics={clientStatsReports?.clientTestReports?.statistics}
            loading={clientStatsReports?.loading}
            show={clientStatsReports?.show}
          />
          <ClientTestCardSection
            client={selectedClient?.value?._id}
            viewId={viewId}
          /> */}
    </>
  );
};
