export const clientSurveyQuestions = [
  {
    question: "Do you use codebase to check your test data?",
    type: "radio",
    options: ["Yes", "No"],
  },
  {
    question: "How frequently are you using codebase per month/week?",
    type: "text",
  },
];

export const REPORTGRAPHTYPES = [
  {
    label: "Conversion Rate Time Series",
    value: "timeseries",
  },
  {
    label: "Probability of being a Winner",
    value: "probability",
  },
];

export const DefaultScrapingAndClassificationJson = {
  name: "Trustpilot Reviews Scraper",
  last_check: Date.now(),
  repeat: 2,
  dump: ["article[class*='reviewCard']"],
  clickPathBefore: ["[id*='onetrust-accept-handler']", "(sleep:5000)"],
  clickPathAfter: ["nav[class*='pagination'] a[aria-label='Next page']"],
  type: "dump",
  url: "https://www.trustpilot.com/review/maxbounty.com",
  scraper: "BrowserStack",
  type_of_review: "general",
  type_of_scarp: "scarp",
};

export const DefaultCrewAiJson = {
  start_date: "2024-01-01",
  end_date: "2025-09-30",
  require_user_response: false,
  user_response: null,
  limit_users: [],
  report: "general analysis of negative reviews",
  chat: [],
  prompt: "Additional prompt for report from user.",
  type_of_review: "general",
  type_of_analysis: "general analysis of negative reviews",
  product_name_for_analysis: "",
};

export const scheduleScraping = [
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
];

export const QueueTaskType = [
  { value: "web_scraping", label: "Web Scraping" },
  { value: "crewai_mm", label: "Crewai MM" },
];
