import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useOutletContext, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  GETGOALS,
  GETHYPOTHESIS,
  GETPAGEOBJECTS,
  GETTAGS,
  GETTESTTYPES,
  GETTRIGGERS,
} from "../../../Graphql/Queries";
import { RowHeading } from "../../../Libs/Utilities";
import useStore from "../../../Store";
import FormInstance from "../../MiroComponents/FormInstance";
import MyLoader from "../../MiroComponents/PreLoader";
import { ClientListIcon, PencilEditIcon } from "../../Shared/Icons";
import { Pagination } from "../../Shared/Paginations";
import { AdminEditActions } from "./AdminEditActions";
import { customStyles } from "../../../Libs/TableCustomStyles";
import { Button } from "../../MiroComponents/Button/Button";

function AddData() {
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const { dataroute } = useParams();
  const { toggleForm, toggleEditForm } = useStore();
  const showForm = useStore((state) => state.showForm);
  const editForm = useStore((state) => state.editForm);
  const getpageobjects = useQuery(GETPAGEOBJECTS);
  const [pageElementFilter, setHypothesisFilter] = useState(null);
  const gettesttypes = useQuery(GETTESTTYPES);
  const getgoals = useQuery(GETGOALS);
  const gettags = useQuery(GETTAGS);
  const [page, setPage] = useState(1);
  const [size] = useState(25);
  const gethypos = useQuery(GETHYPOTHESIS, {
    fetchPolicy: "cache-and-network",
    variables: {
      pageElementFilter,
      fullSet: true,
      page,
      size,
    },
  });
  const gettriggers = useQuery(GETTRIGGERS);
  const [editData, setEditData] = useState(null);

  const setEditNull = () => {
    setEditData(null);
  };

  const TestRowComponent = ({
    _id,
    name,
    action,
    tests,
    winningClients,
    clients,
    expectation,
    tagged,
    result,
    index,
  }) => {
    const [showTip, setShowTip] = useState(false);
    const [showTipWinningClient, setShowTipWinningClient] = useState(false);
    useEffect(() => {
      ReactTooltip.rebuild();
    }, [name]);
    // console.log(tests);
    const getPageObjects = (data) => {
      const _ = data
        ?.reduce((all, { pageelement }) => all.concat(pageelement), [])
        .map(({ name }) => name)
        .join(" , ");
      return _;
    };
    const ClientsList = ({ clients, showTip }) => {
      return (
        <div
          className="clientlist-tip"
          style={showTip ? { opacity: 1 } : { opacity: 0, zIndex: -5 }}
        >
          <ul>
            {clients?.map(({ name }, i) => (
              <li key={i}> {name} </li>
            ))}
          </ul>
        </div>
      );
    };

    return (
      <>
        {index === 0 && (
          <RowHeading
            type="heading"
            headings={[
              {
                name: "Name",
                classes: "hypothesis-name",
              },
              {
                name: "Tagged",
                classes: "hypothesis-tagged",
              },
              {
                name: "Tested",
                classes: "tested-count",
              },
              {
                name: "Won",
                classes: "tested-won",
              },
              {
                name: "Clients",
                classes: "client-hypothesis-list",
              },
              {
                name: "Page/Object",
                classes: "targeted-page-object text-break text-wrap-wrap",
              },
              {
                name: "Hypothesis Details",
                classes: "hypothesis-details",
              },
              {
                name: "Action",
                classes: "action",
              },
            ]}
          />
        )}
        <div className="test-details-table-data">
          <div className="hypothesis-name text-break">
            <span>{name}</span>
          </div>
          <div className="hypothesis-tagged">
            <h3>{tagged} </h3>
          </div>
          <div className="tested-count">
            <span>{tests?.length}</span>
          </div>
          <div className="tested-count">
            <span
              style={
                winningClients?.length > 0
                  ? {
                      cursor: "pointer",
                      color: "#1bc44a",
                      fontSize: "18px",
                      fontWeight: "600",
                    }
                  : {}
              }
              onClick={(_) => {
                if (winningClients.length <= 0) {
                  return;
                }
                setShowTipWinningClient(!showTipWinningClient);
              }}
            >
              {winningClients?.length}
            </span>
            <ClientsList
              clients={winningClients?.map(({ client }) => client)}
              showTip={showTipWinningClient}
            />
          </div>
          <div className="client-hypothesis-list">
            <span
              style={{ cursor: "pointer" }}
              onClick={(_) => setShowTip(!showTip)}
              // onMouseLeave={(_) => setShowTip(false)}
            >
              <ClientListIcon />
            </span>
            <ClientsList clients={clients} showTip={showTip} />
          </div>

          <div className="targeted-page-object">{getPageObjects(tests)}</div>
          <div className="hypothesis-details text-break">
            <p className="hypothesis-procedure hypothesis-if-we-condition">
              <span className="hypothesis-condition">If we : </span> {action}
            </p>
            <p className="hypothesis-procedure hypothesis-then-condition">
              <span className="hypothesis-condition">Then : </span> {result}
            </p>
            <p className="hypothesis-procedure hypothesis-because-condition">
              <span className="hypothesis-condition">Because : </span>{" "}
              {expectation}
            </p>
          </div>
          <div className="action">
            <Button
              className={`smart-button small elipses`}
              icon={<PencilEditIcon />}
              onClick={(_) => {
                setEditData({
                  data: {
                    _id,
                    name,
                    action,
                    expectation,
                    result,
                    tagged,
                  },
                  module: "hypothesis",
                });
                toggleEditForm(true);
              }}
            />
          </div>
        </div>
      </>
    );
  };
  if (dataroute === "page") {
    const columnspage = [
      {
        name: "Name",
        selector: (row) => row.name,
        wrap: true,
      },
      {
        name: "Action",
        cell: (row) => {
          return (
            <div>
              {!is_user && (
                <Button
                  onClick={(_) => {
                    setEditData({
                      data: row,
                      module: "pageobjects",
                    });
                    toggleEditForm(true);
                  }}
                  className={`smart-button small elipses active`}
                  type="button"
                >
                  Edit
                </Button>
              )}
            </div>
          );
        },
        right: true,
        wrap: true,
      },
    ];

    return (
      <div>
        <div className="header-bar">
          <div className="header-bar-inner">
            <h2> Page Type</h2>
            <div>
              {!is_user && (
                <Button
                  className={`smart-button medium elipses active`}
                  onClick={(_) => toggleForm(!showForm)}
                >
                  Add Data
                </Button>
              )}
            </div>
          </div>
        </div>

        {showForm && (
          <FormInstance
            title="Add Page / Object"
            type="addpage"
            getpageobjectsrefetch={getpageobjects?.refetch}
          />
        )}
        {editForm && (
          <AdminEditActions
            data={editData}
            setEditNull={setEditNull}
            refetch={getpageobjects?.refetch}
          />
        )}

        <div className="mt-3">
          <DataTable
            data={getpageobjects?.data?.pageelements}
            columns={columnspage}
            progressComponent={<MyLoader />}
            customStyles={customStyles}
            progressPending={getpageobjects?.loading}
          />
        </div>
      </div>
    );
  } else if (dataroute === "hypothesis") {
    return (
      <div className="test-hypothesis-inner">
        <div className="header-bar">
          <div className="header-bar-inner">
            <h2> Hypothesis</h2>
            <div>
              {!is_user && (
                <Button
                  onClick={(_) => toggleForm(!showForm)}
                  className={`smart-button medium elipses active`}
                  type="button"
                >
                  Add Hypothesis
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="hypothesis-filter">
          <Button
            onClick={(_) => {
              setHypothesisFilter(null);
              setPage(1);
            }}
            className={`smart-button small elipses ${
              !pageElementFilter ? `active` : ``
            }`}
          >
            All
          </Button>
          {getpageobjects?.data?.pageelements.map(({ _id, name }, i) => {
            return (
              <Button
                onClick={(_) => {
                  setHypothesisFilter(_id);
                  setPage(1);
                }}
                className={`smart-button small elipses ${
                  _id === pageElementFilter ? `active` : ``
                }`}
                key={i}
              >
                {name}
              </Button>
            );
          })}
        </div>

        {showForm && (
          <FormInstance
            title="Add Hypothesis"
            type="addhypothesis"
            gethyposrefetch={gethypos.refetch}
            reset={gethypos.reset}
          />
        )}
        {editForm && (
          <AdminEditActions
            data={editData}
            setEditNull={setEditNull}
            refetch={gethypos.refetch}
          />
        )}
        {gethypos?.loading ? (
          <MyLoader />
        ) : (
          <>
            <div className="table-body">
              {gethypos?.data?.allHypothesis?.hypotheses?.map(
                (hypothesis, i) => (
                  <TestRowComponent {...hypothesis} key={i} index={i} />
                )
              )}
            </div>
            <Pagination
              page={page}
              size={size}
              setPage={setPage}
              totalcount={gethypos?.data?.allHypothesis?.total}
            />
          </>
        )}
      </div>
    );
  } else if (dataroute === "triggers") {
    const columnstriggers = [
      {
        name: "Name",
        selector: (row) => row.name,
        wrap: true,
      },
      {
        name: "Details",
        selector: (row) => row?.description,
        wrap: true,
      },
      {
        name: "Action",
        cell: (row) => {
          return (
            <div>
              {!is_user && (
                <>
                  <Button
                    onClick={(_) => {
                      setEditData({
                        data: row,
                        module: "triggers",
                      });
                      toggleEditForm(true);
                    }}
                    className={`smart-button small elipses active`}
                    type="button"
                  >
                    Edit
                  </Button>
                </>
              )}
            </div>
          );
        },
        right: true,
        wrap: true,
      },
    ];
    return (
      <div>
        <div className="header-bar">
          <div className="header-bar-inner">
            <h2> Psychological Triggers</h2>
            <div>
              {!is_user && (
                <Button
                  onClick={(_) => toggleForm(!showForm)}
                  className={`smart-button medium elipses active`}
                  type="button"
                >
                  Add Data
                </Button>
              )}
            </div>
          </div>
        </div>

        {showForm && (
          <FormInstance
            title="Add Triggers"
            type="addtriggers"
            addtriggersrefetch={gettriggers.refetch}
          />
        )}
        {editForm && (
          <AdminEditActions
            data={editData}
            setEditNull={setEditNull}
            refetch={gettriggers.refetch}
          />
        )}
        <div className="mt-3">
          <DataTable
            columns={columnstriggers}
            data={gettriggers?.data?.triggers}
            customStyles={customStyles}
            progressComponent={<MyLoader />}
            progressPending={gettriggers?.loading}
          />
        </div>
      </div>
    );
  } else if (dataroute === "testtypes") {
    const columnspagetesttypes = [
      {
        name: "Name",
        selector: (row) => row.name,
        wrap: true,
      },
      {
        name: "Details",
        selector: (row) => row.details,
        wrap: true,
      },
      {
        name: "Action",
        cell: (row) => {
          return (
            <div>
              {!is_user && (
                <Button
                  onClick={(_) => {
                    setEditData({
                      data: row,
                      module: "testtypes",
                    });
                    toggleEditForm(true);
                  }}
                  className={`smart-button small elipses active`}
                  type="submit"
                >
                  Edit
                </Button>
              )}
            </div>
          );
        },
        right: true,
        wrap: true,
      },
    ];
    return (
      <div>
        <div className="header-bar">
          <div className="header-bar-inner">
            <h2> Test Type</h2>
            <div>
              {!is_user && (
                <Button
                  onClick={(_) => toggleForm(!showForm)}
                  className={`smart-button medium elipses active`}
                  type="button"
                >
                  Add Data
                </Button>
              )}
            </div>
          </div>
        </div>

        {showForm && (
          <FormInstance
            title="Add Test Type"
            type="addtesttype"
            testtyperefetch={gettesttypes.refetch}
          />
        )}
        {editForm && (
          <AdminEditActions
            data={editData}
            setEditNull={setEditNull}
            refetch={gettesttypes.refetch}
          />
        )}

        <div className="mt-3">
          <DataTable
            columns={columnspagetesttypes}
            data={gettesttypes?.data?.pagetesttypes}
            progressComponent={<MyLoader />}
            customStyles={customStyles}
            progressPending={gettesttypes?.loading}
          />
        </div>
      </div>
    );
  } else if (dataroute === "goals") {
    const columnsgoals = [
      {
        name: "Name",
        selector: (row) => row.name,
        wrap: true,
      },
      {
        name: "Details",
        selector: (row) => row.details,
        wrap: true,
      },
      {
        name: "Action",
        cell: (row) => {
          return (
            <div>
              {!is_user && (
                <Button
                  onClick={(_) => {
                    setEditData({
                      data: row,
                      module: "goals",
                    });
                    toggleEditForm(true);
                  }}
                  className={`smart-button small elipses active`}
                  type="button"
                >
                  Edit
                </Button>
              )}
            </div>
          );
        },
        right: true,
        wrap: true,
      },
    ];

    return (
      <div>
        <div className="header-bar">
          <div className="header-bar-inner">
            <h2> Goals</h2>
            <div>
              {!is_user && (
                <Button
                  onClick={(_) => toggleForm(!showForm)}
                  className={`smart-button medium elipses active`}
                  type="button"
                >
                  Add Data
                </Button>
              )}
            </div>
          </div>
        </div>

        {showForm && (
          <FormInstance
            title="Add Goals"
            type="addgoals"
            goalsrefetch={getgoals.refetch}
          />
        )}
        {editForm && (
          <AdminEditActions
            data={editData}
            setEditNull={setEditNull}
            refetch={getgoals.refetch}
          />
        )}
        <div className="mt-3">
          <DataTable
            columns={columnsgoals}
            data={getgoals?.data?.goals}
            progressComponent={<MyLoader />}
            customStyles={customStyles}
            progressPending={getgoals?.loading}
          />
        </div>
      </div>
    );
  } else if (dataroute === "tags") {
    const columnstags = [
      {
        name: "Name",
        selector: (row) => row.name,
        wrap: true,
      },
      {
        name: "Action",
        cell: (row) => {
          return (
            <div>
              {!is_user && (
                <Button
                  onClick={(_) => {
                    setEditData({
                      data: row,
                      module: "tags",
                    });
                    toggleEditForm(true);
                  }}
                  className={`smart-button small elipses active`}
                  type="button"
                >
                  Edit
                </Button>
              )}
            </div>
          );
        },
        right: true,
        wrap: true,
      },
    ];

    return (
      <div>
        <div className="header-bar">
          <div className="header-bar-inner">
            <h2> Tags</h2>
            <div>
              {!is_user && (
                <Button
                  className={`smart-button medium elipses active`}
                  onClick={(_) => toggleForm(!showForm)}
                >
                  Add Data
                </Button>
              )}
            </div>
          </div>
        </div>
        {showForm && (
          <FormInstance
            title="Add Tags"
            type="addtag"
            tagsrefetch={gettags.refetch}
          />
        )}
        {editForm && (
          <AdminEditActions
            data={editData}
            setEditNull={setEditNull}
            refetch={gettags.refetch}
          />
        )}
        <div className="mt-3">
          <DataTable
            columns={columnstags}
            data={gettags?.data?.tags}
            progressComponent={<MyLoader />}
            customStyles={customStyles}
            progressPending={getgoals?.loading}
          />
        </div>
      </div>
    );
  } else {
    return "No Route Found";
  }
}

export default AddData;
