import React, { useCallback, useEffect, useMemo } from "react";
import NoDataFound from "../MiroComponents/NoDataFound/NoDataFound";
import { useLazyQuery } from "@apollo/client";
import { GETTESTPRECALCULATIONPROCESSING } from "../../Graphql/Queries";
import MyLoader from "../MiroComponents/PreLoader";
import { SmartInput } from "../MiroComponents/SmartInput/SmartInput";
import { SmartSelector } from "../MiroComponents/SmartSelector/SmartSelector";
import SelectComponent from "../MiroComponents/SelectComponent/SelectComponent";
import { StoreIcon } from "./Icons";
import _, { set } from "lodash";

const pretypes = [
  {
    title: "CR",
    key: "crformvalues",
  },
  {
    title: "AOV",
    key: "aovrpuformvalues",
  },
  {
    title: "RPU",
    key: "aovrpuformvalues",
  },
];

export const PreCalculatedValues = ({
  precalculations,
  setFormPreData,
  formPreData,
  numberofvariants,
  setNumberofvariants,
}) => {
  // console.log(numberofvariants);
  // console.log("precalculations", precalculations, "tes");
  // console.log(formPreData);
  // Debounce function using useCallback
  const debounceSetValue = useCallback(
    _.debounce((newValue, callback) => {
      callback(newValue);
    }, 500),
    []
  );

  const handleChange = (value, callback) => {
    debounceSetValue(value, callback);
  };

  return (
    <div>
      <div className="seq-values">
        <p>
          Number of Weeks : <span>{precalculations?.weeks}</span>
        </p>
      </div>

      <div className="default-margin-top">
        <div className="default-padding default-border">
          <div className="flex justify-content-between default-column-gap">
            <div className="full-width">
              <SmartInput
                label="Number of variants (including control)"
                defaultValue={numberofvariants}
                type="number"
                onChange={(e) => {
                  handleChange(
                    Number(e.target.value) || 2,
                    setNumberofvariants
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {pretypes.map((item, index) => (
        <div key={index} className="big-margin-top">
          {precalculations[item?.title]?.response && (
            <>
              <h1> {item?.title}</h1>
              {precalculations[item?.title]?.rows?.map((row, index) => (
                <React.Fragment key={index}>
                  <div className="impressions" id="total">
                    <div className="impression-data">
                      <div className="impression-heading">
                        <h4>Users</h4>
                      </div>
                      <span className="data-count">{row?.users}</span>
                    </div>

                    <div className="impression-data">
                      <div className="impression-heading">
                        <h4>Transactions</h4>
                      </div>
                      <span className="data-count">{row?.transactions}</span>
                    </div>

                    <div className="impression-data">
                      <div className="impression-heading">
                        <h4>CR</h4>
                      </div>
                      <span className="data-count">{row?.cr?.toFixed(2)}%</span>
                    </div>

                    <div className="impression-data">
                      <div className="impression-heading">
                        <h4>Purchasers</h4>
                      </div>
                      <span className="data-count">{row?.purchasers}</span>
                    </div>
                    <div className="impression-data">
                      <div className="impression-heading">
                        <h4>Revenue</h4>
                      </div>
                      <span className="data-count">
                        {row?.revenue?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <div
                    className="impressions"
                    id="total"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <div className="impression-data">
                      <div className="impression-heading">
                        <h4>Average AOV</h4>
                      </div>
                      <span className="data-count">
                        {row?.avg_aov?.toFixed(2)}
                      </span>
                    </div>

                    <div className="impression-data">
                      <div className="impression-heading">
                        <h4>AVG RPU</h4>
                      </div>
                      <span className="data-count">
                        {row?.avg_rpu?.toFixed(2)}
                      </span>
                    </div>

                    <div className="impression-data">
                      <div className="impression-heading">
                        <h4>Std. Dev. AOV</h4>
                      </div>
                      <span className="data-count">
                        {row?.stddev_aov?.toFixed(2)}
                      </span>
                    </div>

                    <div className="impression-data">
                      <div className="impression-heading">
                        <h4>Std. Dev. RPU</h4>
                      </div>
                      <span className="data-count">
                        {row?.stddev_rpu?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              ))}

              <div className="default-margin-top">
                <div className="default-padding default-border">
                  <h2>Test Page data</h2>
                  <div className="flex justify-content-between default-column-gap">
                    <div className="full-width">
                      <SmartInput
                        label="Weekly traffic (sessions or users)"
                        defaultValue={
                          precalculations[item?.title]?.response?.weeklyusers
                        }
                        type="number"
                        readOnly
                      />
                    </div>
                    <div className="full-width">
                      <SmartInput
                        label="Weekly conversions"
                        defaultValue={
                          precalculations[item?.title]?.response
                            ?.weeklyconversions
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="default-margin-top mde-cal-wrapper">
                <div className="default-border">
                  <div className="flex justify-content-between mde-cal-inner">
                    <div className="default-padding bg-dark radius-2 full-width mde-cal-form">
                      <h2>{item?.title}, confidence level, and power</h2>
                      <div className="full-width">
                        <SmartInput
                          label={
                            item?.title === "CR"
                              ? `Baseline conversion rate (control)`
                              : item?.title
                          }
                          defaultValue={
                            item?.title === "CR"
                              ? precalculations[item?.title]?.response
                                  ?.baselineconversionRate
                              : precalculations[item?.title]?.response?.[
                                  item?.title?.toUpperCase()
                                ]
                          }
                          className={item?.title === "CR" ? "percent" : "money"}
                          readOnly
                        />
                      </div>
                      <div className="full-width">
                        <SmartInput
                          label="Confidence level"
                          defaultValue={
                            item?.title === "CR"
                              ? formPreData?.crformvalues?.confidencelevel * 100
                              : formPreData?.aovrpuformvalues?.confidencelevel *
                                100
                          }
                          onChange={(e) => {
                            const key =
                              item?.title === "CR"
                                ? "crformvalues"
                                : "aovrpuformvalues";
                            handleChange(
                              {
                                ...formPreData,
                                [key]: {
                                  ...formPreData[key],
                                  confidencelevel: e.target.value / 100,
                                },
                              },
                              setFormPreData
                            );
                          }}
                        />
                      </div>
                      <div className="full-width">
                        <SmartInput
                          label="Statistical power"
                          defaultValue={
                            item?.title === "CR"
                              ? formPreData?.crformvalues?.statisticalpower *
                                100
                              : formPreData?.aovrpuformvalues
                                  ?.statisticalpower * 100
                          }
                          onChange={(e) => {
                            const key =
                              item?.title === "CR"
                                ? "crformvalues"
                                : "aovrpuformvalues";
                            handleChange(
                              {
                                ...formPreData,
                                [key]: {
                                  ...formPreData[key],
                                  statisticalpower: e.target.value / 100,
                                },
                              },
                              setFormPreData
                            );
                          }}
                        />
                      </div>
                      <div className="full-width">
                        <SmartSelector
                          options={[
                            {
                              label: "1-tailed",
                              value: "1-tailed",
                            },
                            {
                              label: "2-tailed",
                              value: "2-tailed",
                            },
                          ]}
                          value={
                            item?.title === "CR"
                              ? {
                                  label: formPreData?.crformvalues?.tailedValue,
                                  value: formPreData?.crformvalues?.tailedValue,
                                }
                              : {
                                  label:
                                    formPreData?.aovrpuformvalues?.tailedValue,
                                  value:
                                    formPreData?.aovrpuformvalues?.tailedValue,
                                }
                          }
                          placeholder="Select Value"
                          icon={<StoreIcon />}
                          onChange={(e) => {
                            const key =
                              item?.title === "CR"
                                ? "crformvalues"
                                : "aovrpuformvalues";
                            handleChange(
                              {
                                ...formPreData,
                                [key]: {
                                  ...formPreData[key],
                                  tailedValue: e.value,
                                },
                              },
                              setFormPreData
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className=" full-width mde-cal-values default-border-right default-padding-left">
                      <h2>Number of weeks running test</h2>
                      {precalculations[item?.title]?.response?.mderesult?.map(
                        (mde, index) => (
                          <div className="full-width" key={index}>
                            <SmartInput defaultValue={mde?.week} readOnly />
                          </div>
                        )
                      )}
                    </div>
                    <div className="full-width mde-cal-values default-border-right">
                      <h2>Minimum detectable effect (MDE)</h2>
                      {precalculations[item?.title]?.response?.mderesult?.map(
                        (mde, index) => (
                          <div
                            className={`full-width ${
                              mde?.closest ? `closest` : ``
                            }`}
                            key={index}
                          >
                            <SmartInput
                              defaultValue={`${mde?.mde} %`}
                              readOnly
                            />
                          </div>
                        )
                      )}
                    </div>

                    <div className="full-width mde-cal-values">
                      <h2>
                        Visitors per <br /> variant{" "}
                      </h2>
                      {precalculations[item?.title]?.response?.mderesult?.map(
                        (mde, index) => (
                          <div className="full-width" key={index}>
                            <SmartInput
                              defaultValue={`${mde?.sampleSizePerVariant?.toLocaleString()}`}
                              readOnly
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ))}

      {/* <pre
        style={{
          color: "#fff",
        }}
      >
        <code>{query}</code>
      </pre> */}
    </div>
  );
};
