import { Route, Routes, Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ClientLayout from "./Components/Layouts/ClientLayout";
import ReactTooltip from "react-tooltip";
import { EditTestForm } from "./Components/MiroComponents/EditTestForm";
import AddData from "./Components/Pages/Admin/AddData";
import { AdminEditActions } from "./Components/Pages/Admin/AdminEditActions";
import Clients from "./Components/Pages/Admin/Clients";
import ClientAccesses from "./Components/Pages/Admin/ClientAccesses";
import { CreateSegments } from "./Components/Pages/Admin/CreateSegments";
import Dashboard from "./Components/Pages/Admin/Dashboard";
import { ImportCsvData } from "./Components/Pages/Admin/ImportCsvData";
import { Snippets } from "./Components/Pages/Admin/Snippets";
import Tests from "./Components/Pages/Admin/Tests";
import Users from "./Components/Pages/Admin/Users";
import AppEvents from "./Components/Shared/AppEvents";
import ClientTest from "./Components/Pages/Client/ClientTests";
import Login from "./Components/Pages/Login";
import { GoogleAnalyticsReporting } from "./Components/Shared/GoogleAnalyticsReporting";
import { Redirect } from "./Components/Shared/Redirect";
import { ReportDetails } from "./Components/Shared/ReportDetails";
import { TwoFactorAuthentication } from "./Components/Shared/TwoFactorAuthentication";
import MyRoutes from "./Routes";
import { NotFound } from "./Components/Pages/NotFound";
import { ClientReportsAdmin } from "./Components/Pages/Admin/ClientReportsAdmin";
import { ClientAudienceInsights } from "./Components/Pages/Client/ClientAudienceInsights";
import { NotificationDetails } from "./Components/Shared/NotificationDetails";
import NotificationLayout from "./Components/Layouts/NotificationLayout";
import { ClientFeedbacks } from "./Components/Pages/Admin/ClientFeedbacks";
import { SendFeedback } from "./Components/Pages/Client/SendFeedback";
import { GetReportFromTestId } from "./Components/Shared/GetReportFromTestId";
import ClientsRunningTests from "./Components/Pages/Admin/ClientRunningTests";
import { Announcement } from "./Components/Pages/Admin/Announcement";
import { Faq } from "./Components/Shared/Faq";
import useStore from "./Store";
import { ManageFaqs } from "./Components/Pages/Admin/ManageFaqs";
import { ShareReport } from "./Components/Shared/ShareReport";
import { AdminPortfoliComparison } from "./Components/Pages/Admin/AdminPortfolioComparison";
import AuthLayoutNew from "./Components/Layouts/AuthLayoutNew";
import { ManageClient } from "./Components/Pages/Admin/ManageClient";
import { ManageClientRetentions } from "./Components/Pages/Admin/ManageClientRetentions";
import ClientLinks from "./Components/Pages/Admin/ClientLinks";
import LinksAndDocuments from "./Components/Pages/Client/LinksAndDocuments";
import ClientReportsLeanings from "./Components/Pages/Admin/ClientReportsLeanings";
import { EditorDashboard } from "./Components/Pages/Editor/EditorDashboard";
import { ClientAudit } from "./Components/Pages/Admin/ClientAudit";
import ResetPassword from "./Components/Pages/ResetPassword";
import ChangePassword from "./Components/Pages/ChangePassword";
import { PreCalculations } from "./Components/Pages/Admin/PreCalculations";
import { CVRReports } from "./Components/Pages/Admin/CVRReports";
import { ClientCVRReport } from "./Components/Pages/Admin/ClientCVRReport";
import ClientSurveyReports from "./Components/Pages/Admin/ClientSurveyReports";
import { Testideas } from "./Components/Pages/Admin/Testideas";
import Landingpages from "./Components/Pages/Admin/Landingpages";
import TopBarProgress from "react-topbar-progress-indicator";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ClientFetchAdditionalRevenue } from "./Components/Pages/Admin/ClientFetchAdditionalRevenue";
import { ClientGetCvrReports } from "./Components/Pages/Client/ClientGetCvrReports";
import { BusinessProjection } from "./Components/Pages/Admin/BusinessProjection";
import { ClientBusinessProjection } from "./Components/Pages/Admin/ClientBusinessProjection";
import { ABTestingDashboard } from "./Components/Pages/Admin/ABTestingDashboard";
import ExperimentDetails from "./Components/NewComponents/ExperimentDetails/ExperimentDetails";
import AbTestScriptCheck from "./Components/Pages/AbTestScriptCheck";
import ExperimentResults from "./Components/NewComponents/ExperimentResults/ExperimentResults";
import "bootstrap/dist/css/bootstrap.css";
import "./Styles/Reset.scss";
import "./Styles/App.scss";
import ReportDetailsNew from "./Components/NewComponents/ReportDetailsNew/ReportDetailsNew";
import ScrollToTop from "./Components/NewComponents/ScrollToTop/ScrollToTop";
import CreateManualReport from "./Components/NewComponents/CreateManualReport/CreateManualReport";
import PreviewManualReport from "./Components/NewComponents/PreviewManualReport/PreviewManualReport";
import ReviewReports from "./Components/Pages/Admin/ReviewReports";
import ReviewAnalysis from "./Components/Pages/Admin/ReviewAnalysis";
import { ClientReportsAdminManageReport } from "./Components/Pages/Admin/ClientReportsAdminManageReport";

function App() {
  const showFaq = useStore((state) => state.showFaq);
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;

  const [progress, setProgress] = useState(false);
  const [prevLoc, setPrevLoc] = useState("");
  const location = useLocation();

  useEffect(() => {
    setPrevLoc(location.pathname);
    setProgress(true);
    if (location.pathname === prevLoc) {
      setPrevLoc("");
    }
  }, [location]);

  useEffect(() => {
    setProgress(false);
  }, [prevLoc]);

  if (isIE)
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        This app not compatible with your browser, please switch to other
        browser.
      </div>
    );

  return (
    <div className="App">
      <ScrollToTop>
        {progress && <TopBarProgress />}
        <ToastContainer />
        <ReactTooltip place="top" effect="solid" className="swtooltip" />
        <Routes>
          {/* <Route element={<PublicLayout />}> */}
          <Route path="/" element={<Login />} />
          <Route path="/faqs" element={<Faq />} />
          <Route path="/auth/login" element={<Login />} />
          <Route
            path="/two-factor-auth"
            element={<TwoFactorAuthentication />}
          />
          <Route path="/authorized" element={<Redirect />} />
          <Route path="/report-details/:_id" element={<ReportDetails />} />
          <Route path="/getreportdetails/" element={<GetReportFromTestId />} />
          <Route path="/sharereport/:_id" element={<ShareReport />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/abtest" element={<AbTestScriptCheck />} />
          <Route
            path="/change-password/:user_id/:token"
            element={<ChangePassword />}
          />

          <Route
            path={MyRoutes.businesscaseprojections}
            element={<BusinessProjection />}
          />

          {/* <Route
          path="/getreportdetails/:_id"
          element={<GetReportFromTestId />}
        /> */}
          {/* </Route> */}
          <Route element={<AuthLayoutNew />}>
            <Route path={MyRoutes.dashboard} element={<Dashboard />} />
            <Route
              path={MyRoutes.editorDashboard}
              element={<EditorDashboard />}
            />
            <Route path={MyRoutes.clients} element={<Clients />} />
            <Route
              path={`${MyRoutes.manageclient}/:clientId`}
              element={<ManageClient />}
            />
            <Route
              path={`${MyRoutes.report}/:id/:report`}
              element={<ReportDetailsNew />}
            />
            <Route
              path={MyRoutes.clientbusinesscaseprojections}
              element={<ClientBusinessProjection />}
            />
            <Route
              path={`${MyRoutes.manageclientretentions}/:clientId`}
              element={<ManageClientRetentions />}
            />
            <Route
              path={MyRoutes.testsoverview}
              element={<ClientsRunningTests />}
            />
            <Route path={MyRoutes.tests} element={<Tests />} />
            {/* <Route path="/edittest/:_id" element={<EditTestForm />} /> */}
            <Route path={MyRoutes.importcsv} element={<ImportCsvData />} />
            <Route path={MyRoutes.snippets} element={<Snippets />} />
            <Route path={MyRoutes.data} element={<AddData />} />
            <Route
              path={MyRoutes.createsegments}
              element={<CreateSegments />}
            />
            <Route
              path={MyRoutes.reviewAnalysis}
              element={<ReviewAnalysis />}
            />
            <Route path={MyRoutes.reportReviews} element={<ReviewReports />} />
            <Route path={MyRoutes.faqs} element={<ManageFaqs />} />
            <Route path={MyRoutes.cvrReports} element={<CVRReports />} />
            <Route
              path={MyRoutes.clientreports}
              element={<ClientReportsAdmin />}
            />
            <Route
              path={`${MyRoutes.clientreports}/manage/:_id`}
              element={<ClientReportsAdminManageReport />}
            />
            <Route
              path={MyRoutes.clientreportslearnings}
              element={<ClientReportsLeanings />}
            />
            <Route
              path={MyRoutes.portfoliocomparison}
              element={<AdminPortfoliComparison />}
            />
            <Route
              path="/edit/:module/:_id/:fields"
              element={<AdminEditActions />}
            />
            <Route path={`${MyRoutes.data}/:dataroute`} element={<AddData />} />
            <Route path={MyRoutes.users} element={<Users />} />
            <Route
              path={MyRoutes.clientsaccesses}
              element={<ClientAccesses />}
            />
            <Route path={MyRoutes.appevents} element={<AppEvents />} />
            <Route
              path={MyRoutes.clientsurveyreport}
              element={<ClientSurveyReports />}
            />
            <Route path={MyRoutes.testideas} element={<Testideas />} />
            <Route path={MyRoutes.landingpages} element={<Landingpages />} />
            {/* <Route
            path={MyRoutes.clientfeedbacks}
            element={<ClientFeedbacks />}
          /> */}
            <Route
              path={MyRoutes.gaprecalculations}
              element={<PreCalculations />}
            />
            <Route path={MyRoutes.announcements} element={<Announcement />} />
            <Route path={MyRoutes.clientaudit} element={<ClientAudit />} />
            <Route path={MyRoutes.clientlinks} element={<ClientLinks />} />
            <Route
              path={MyRoutes.gaanalytics}
              element={<GoogleAnalyticsReporting />}
            />
            <Route
              path={MyRoutes.refetchAddtionalRevenueData}
              element={<ClientFetchAdditionalRevenue />}
            />
            <Route
              path={MyRoutes.abtestingdashboard}
              element={<ABTestingDashboard />}
            />
            <Route
              path={`${MyRoutes.abtestingdashboard}/:module`}
              element={<ABTestingDashboard />}
            />
            {/* <Route
              path={`${MyRoutes.abtestingdashboard}/:module`}
              element={<ABTestingDashboard />}
            />
            <Route
              path={`${MyRoutes.abtestingdashboard}/audiencetargetings`}
              element={<ABTestingDashboard />}
            />
            <Route
              path={`${MyRoutes.abtestingdashboard}/goals`}
              element={<ABTestingDashboard />}
            /> */}
            <Route
              path={`${MyRoutes.abtestingdashboard}/:module/:experimentId`}
              element={<ExperimentDetails />}
            />
            <Route
              path={`${MyRoutes.createmanualreport}/:reportId`}
              element={<CreateManualReport />}
            />
            <Route
              path={`${MyRoutes.previewmanualreport}`}
              element={<PreviewManualReport />}
            />

            <Route
              path={`${MyRoutes.abtestingdashboard}/:experimentId/experimentresults`}
              element={<ExperimentResults />}
            />
          </Route>

          {/* Notification Layout */}
          <Route element={<NotificationLayout />}>
            <Route
              path="/notification/:module"
              element={<NotificationDetails />}
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
    </div>
  );
}

export default App;
